import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IWebSocketMessage } from 'src/app/interfaces/wsinterface';
import { ButtonModel } from 'src/app/models/buttonmodel';
import { Line } from 'src/app/models/linemodel';
import { LinesrepositoryService } from 'src/app/services/repository/lines/linesrepository.service';
import { WebsocketService } from 'src/app/services/repository/websocket/websocket.service';
import { sendAlert } from 'src/app/services/utils';

@Component({
  selector: 'app-createline',
  templateUrl: './createline.component.html',
  styleUrls: ['./createline.component.scss'],
})
export class CreatelineComponent implements OnInit {
  @Input() title: string = 'Inserindo Linha';
  @Input() values: Line = new Line();
  orientation: any = [
    { value: 'vertical', label: 'Vertical' },
    { value: 'horizontal', label: 'Horizontal' },
  ];

  public loading: boolean = false;
  public detecton: boolean = false;
  public subscriberButton: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Line,
    private linerep: LinesrepositoryService,
    public dialogRef: MatDialogRef<CreatelineComponent>,
    public ws: WebsocketService
  ) {}

  ngOnInit(): void {
    this.values = this.data;
    if (this.data.IDline) {
      this.title = 'Editando Linha';
      console.log('[Editando linha] => ', this.values);
    }
  }

  async send() {
    this.loading = true;
    try {
      if (this.values.IDline) await this.linerep.edit(this.values);
      else await this.linerep.set(this.values);
      this.dialogRef.close(this.values);
    } catch (error) {
      sendAlert('Erro ao Inserir/Editar Motivo de Parada!', 'danger');
    }
    this.loading = false;
  }

  get properties() {
    return {
      'background-color': this.values.leveldata.cor,
      width: this.values.getPropWidth,
      height: this.values.getPropHeight,
      'writing-mode': this.values.getTextOrientation,
    };
  }

  detectbutton() {
    this.detecton = true;
    this.subscriberButton = this.ws
      .messager('frontend', ['buttontoline'])
      .subscribe((res: IWebSocketMessage) => {
        console.log('message =>', res);
        const button = ButtonModel.fromJson(res.payload);
        console.log('Button pressed => ', button);
        this.values.IDbutton = String(button.IDbutton);
        this.detecton = false;
        sendAlert('Botão detectado!', 'success');
      });
  }
}
