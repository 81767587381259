<section id="modal">
  <div class="headermodal">
    <div class="titlemodal">{{ title }}</div>
    <icon
      icon="/assets/icons/closeicon.svg"
      width="20px"
      height="20px"
      class="iconmodal"
      mat-dialog-close
    ></icon>
  </div>
  <div class="bodymodal">
    <div class="structmodal">
      <div class="formdata">
        <inputcomponent
          hinttext="ID"
          marginBottom="4px"
          [(data)]="values.IDline"
          type="text"
          style="width: 20%"
          disabled="true"
        ></inputcomponent>
        <inputcomponent
          hinttext="Descrição da Linha"
          marginBottom="4px"
          [(data)]="values.label"
          type="text"
          style="width: 80%"
        ></inputcomponent>
        <selectcomponent
          hinttext="Orientação do Texto"
          magin="12px"
          (selectedChange)="values.textOrientation = $event.value"
          [(data)]="orientation"
          [pattern]="values.textOrientation"
          labelkey="label"
          idkey="value"
          style="width: 100%"
        ></selectcomponent>
        <inputcomponent
          hinttext="Posição Inicial (X)"
          marginBottom="4px"
          [(data)]="values.posx"
          type="number"
          style="width: 50%"
        ></inputcomponent>
        <inputcomponent
          hinttext="Posição Inicial (Y)"
          marginBottom="4px"
          [(data)]="values.posy"
          type="number"
          style="width: 50%"
        ></inputcomponent>
        <inputcomponent
          hinttext="Largura (Tamanho em X)"
          marginBottom="4px"
          [(data)]="values.width"
          type="number"
          style="width: 50%"
        ></inputcomponent>
        <inputcomponent
          hinttext="Comprimento (Tamanho em Y)"
          marginBottom="4px"
          [(data)]="values.height"
          type="number"
          style="width: 50%"
        ></inputcomponent>
        <inputcomponent
          hinttext="Botão"
          marginBottom="4px"
          [(data)]="values.IDbutton"
          type="text"
          style="width: 50%"
          disabled="true"
        ></inputcomponent>
        <div class="buttondetect" (click)="detectbutton()">
          <icon icon="/assets/icons/minispin.png" width="30px" height="30px" *ngIf="detecton" class="iconspin"></icon>
          <icon icon="/assets/icons/wireless.svg" width="30px" height="30px" *ngIf="!detecton"></icon>
          <div class="labelbuttondetect" *ngIf="!detecton">Click para detectar</div >
            <div class="labelbuttondetect" *ngIf="detecton">Buscando sinal do botão...</div >
        </div>
      </div>
      <billboard title="Resultado" height="192px" width="329px">
        <div class="square" [ngStyle]="properties">
          {{ values.label }}
        </div>
      </billboard>
    </div>
    <br />
    <div style="float: right; margin-top: 12px; margin-bottom: 20px">
      <buttoncomponent
        text="Salvar"
        background="var(--color-primary)"
        (onClick)="send()"
      ></buttoncomponent>
    </div>
  </div>
  <spinner *ngIf="loading">Carregando...</spinner>
</section>
