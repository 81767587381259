import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginModel } from 'src/app/models/loginmodel';
import { LoginrepositoryService } from 'src/app/services/repository/login/loginrepository.service';
import { StoreService } from 'src/app/services/storage/store.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public formdata: LoginModel = new LoginModel();
  public loading: boolean = false;

  constructor(
    private loginrep: LoginrepositoryService,
    private router: Router,
    private store: StoreService
  ) {}

  ngOnInit(): void {}

  async login() {
    this.loading = true;
    try {
      this.store.user = LoginModel.fromJson(
        await this.loginrep.login(this.formdata)
      );
      console.log(this.store.user);
      
      if (this.store.user.level) {
        this.router.navigate([`/home`]);
      }
    } catch (error) {}
    this.loading = false;
  }
}
