<main id="main">
    <div class="header">
      <div class="title">Gerenciamento de Botoeiras</div>
      <!-- <div class="menuicon" (click)="new()">
        <icon icon="/assets/icons/addnew.svg" width="32px" height="32px"></icon>
      </div> -->
    </div>
    <div class="container">
      <tablecomponent
        #tableComponent
        [columnsConfig]="configTable"
        [values]="data"
        [headerConfig]="TableHeaderConfig"
        [download]="true"
        (onDownload)="downloader.download($event, configTable, 'botoes')"
        title="Lista de Botoeiras Cadastradas"
        *ngIf="!loading"
        height="88vh"
      ></tablecomponent>
    </div>
  </main>
  <menu #menu></menu>
  <spinner *ngIf="loading">Carregando...</spinner>
  