import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Reason } from 'src/app/models/reasonmodel';
import { ReasonsrepositoryService } from 'src/app/services/repository/reasons/reasonsrepository.service';
import { sendAlert } from 'src/app/services/utils';

@Component({
  selector: 'app-createreason',
  templateUrl: './createreason.component.html',
  styleUrls: ['./createreason.component.scss'],
})
export class CreatereasonComponent implements OnInit {
  @Input() title: string = 'Inserindo Motivo de Parada';
  @Input() values: Reason = new Reason();
  public loading: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Reason,
    public dialogRef: MatDialogRef<CreatereasonComponent>,
    private reasonrep: ReasonsrepositoryService
  ) {}

  ngOnInit(): void {
    if (this.data.IDreasonOff) {
      this.values = this.data;
      this.title = 'Editando Motivo de Parada';
    }
  }

  async send() {
    this.loading = true;
    try {
      if (this.values.IDreasonOff) await this.reasonrep.edit(this.values);
      else await this.reasonrep.set(this.values);
      this.dialogRef.close(this.values);
    } catch (error) {
      sendAlert('Erro ao Inserir/Editar Motivo de Parada!', 'danger');
    }
    this.loading = false;
  }
}
