import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Sector } from 'src/app/models/sectormodel';
import { SectorrepositoryService } from 'src/app/services/repository/sectors/sectorrepository.service';
import { sendAlert } from 'src/app/services/utils';

@Component({
  selector: 'app-createsector',
  templateUrl: './createsector.component.html',
  styleUrls: ['./createsector.component.scss'],
})
export class CreatesectorComponent implements OnInit {
  @Input() title: string = 'Inserindo Setor';
  @Input() values: Sector = new Sector();
  public loading: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Sector,
    public dialogRef: MatDialogRef<CreatesectorComponent>,
    private sectorrep: SectorrepositoryService
  ) {}

  ngOnInit(): void {
    if (this.data.IDsector) {
      this.values = this.data;
      this.title = 'Editando Setor';
    }
  }

  async send() {
    this.loading = true;
    try {
      if (this.values.IDsector) await this.sectorrep.edit(this.values);
      else await this.sectorrep.set(this.values);
      this.dialogRef.close(this.values);
    } catch (error) {
      sendAlert('Erro ao Inserir/Editar Setor!', 'danger');
    }
    this.loading = false;
  }
}
