import { Header } from './header';

export class HeaderJson extends Header {
  get jsonheader() {
    this.headerobject.append('Content-Type', 'application/json');
    this.headerobject.append('Accept-Encoding', 'application/json');
    this.headerobject.append('Connection', 'keep-alive');
    return this.headerobject;
  }
}
