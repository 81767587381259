import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

export class XLSXdownloader {
  constructor() {}

  async toExcel(
    json,
    filename,
    wscols: any = null,
    autouid: boolean = true
  ): Promise<any> {
    return new Promise((resolve) => {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
      if (wscols) {
        worksheet['!cols'] = wscols.map((item: any) => {
          return { wch: item };
        });
      }
      const workbook: XLSX.WorkBook = {
        Sheets: { Dados: worksheet },
        SheetNames: ['Dados'],
      };
      let uid = autouid ? new Date().getTime() : '';
      XLSX.writeFile(workbook, filename + uid + '.xlsx');
      resolve('');
    });
  }

  async downloadZip(res, namefile) {
    const blob = new Blob([res], {
      type: 'application/zip',
    });
    saveAs(blob, namefile);
  }
}
