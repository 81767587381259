import { ISector } from '../interfaces/sectorinterface';

export class Sector implements ISector {
  IDsector: string;
  sector: string;

  public static fromJson(data: ISector) {
    let sector = new Sector();
    sector.IDsector = data.IDsector;
    sector.sector = data.sector;
    return sector;
  }

  public toJson() {
    return { IDsector: this.IDsector, sector: this.sector };
  }
}
