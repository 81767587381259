import { DashboardComponent } from 'src/app/pages/dashboard/dashboard.component';
import { IMenuoption } from '../interfaces/menuinterface';
import { AboutComponent } from '../pages/about/about.component';
import { AlertsComponent } from '../pages/alerts/alerts.component';
import { ButtonsComponent } from '../pages/buttons/buttons.component';
import { LinesComponent } from '../pages/lines/lines.component';
import { LoginComponent } from '../pages/login/login.component';
import { OccurrencespageComponent } from '../pages/occurrencespage/occurrencespage.component';
import { ReasonsComponent } from '../pages/reasons/reasons.component';
import { SectorsComponent } from '../pages/sectors/sectors.component';
import { UsersComponent } from '../pages/users/users.component';

export const MENU_OPTIONS: IMenuoption[] = [
  {
    path: '',
    component: LoginComponent,
    showinmenu: false,
    levelAccess: 0,
  },
  {
    path: 'home',
    component: DashboardComponent,
    icon: '/assets/icons/dashboard.png',
    label: 'Central',
    showinmenu: true,
    levelAccess: 0,
  },
  {
    icon: '/assets/icons/report.png',
    label: 'Relatórios',
    showinmenu: true,
    levelAccess: 0,
    submenu: [
      {
        icon: '/assets/icons/reportoccurrences.svg',
        path: 'reports',
        label: 'Ocorrências',
        component: OccurrencespageComponent,
        showinmenu: true,
        levelAccess: 0,
      },
    ],
  },
  {
    icon: '/assets/icons/manage.png',
    label: 'Gerenciamento',
    showinmenu: true,
    levelAccess: 999,
    submenu: [
      {
        icon: '/assets/icons/lines.svg',
        path: 'lines',
        label: 'Linhas',
        showinmenu: true,
        component: LinesComponent,
        levelAccess: 999,
      },
      {
        icon: '/assets/icons/switch.svg',
        label: 'Botoeiras',
        showinmenu: true,
        levelAccess: 999,
        component: ButtonsComponent,
        path: 'buttons',
      },
      {
        icon: '/assets/icons/alert.svg',
        label: 'Alertas',
        showinmenu: true,
        levelAccess: 999,
        component: AlertsComponent,
        path: 'alerts',
      },
      {
        path: 'users',
        icon: '/assets/icons/user.svg',
        label: 'Usuários',
        showinmenu: true,
        component: UsersComponent,
        levelAccess: 999,
      },
      {
        icon: '/assets/icons/sector.svg',
        label: 'Setores',
        showinmenu: true,
        levelAccess: 999,
        component: SectorsComponent,
        path: 'sectors',
      },
      {
        icon: '/assets/icons/break.svg',
        label: 'Motivos de Parada',
        showinmenu: true,
        levelAccess: 999,
        component: ReasonsComponent,
        path: 'reasons',
      },
    ],
    closed: true,
  },
  {
    path: 'about',
    component: AboutComponent,
    icon: '/assets/icons/info.png',
    label: 'Sobre',
    showinmenu: true,
    levelAccess: 0,
  },
  {
    icon: '/assets/icons/out.svg',
    label: 'Sair',
    showinmenu: true,
    levelAccess: 0,
    exit:true,
  },
];

export const getRoutes = (): IMenuoption[] => {
  let menu_routes = MENU_OPTIONS.filter((item) => item.component);
  MENU_OPTIONS.forEach((item) => {
    if (item.submenu) {
      item.submenu.forEach((submenu) => {
        if (submenu.component) menu_routes.push(submenu);
      });
    }
  });
  return menu_routes;
};
