import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ILevel } from 'src/app/interfaces/levelsinterface';
import { Level } from 'src/app/models/levelsmodel';
import { environment } from 'src/environments/environment';
import { sendAlert } from '../../utils';
import { HeaderJson } from '../headers/jsonheader';

const URL_SERVER = environment.url_server;

@Injectable({
  providedIn: 'root',
})
export class LevelsrepositoryService {
  private header: HeaderJson = new HeaderJson();

  constructor(private http: HttpClient) {}

  async get(): Promise<ILevel[]> {
    try {
      const res = await this.http.get(`${URL_SERVER}/alerta`, {headers: this.header.jsonheader}).toPromise();
      // const res = await this.http.get('/assets/api/levels.json').toPromise();
      // console.log(`${URL_SERVER}/alerta => `, res);

      return res as ILevel[];
    } catch (error) {
      console.error(error);
      sendAlert('Erro ao Consultar Alertas!', 'danger');
      return [];
    }
  }

  async set(data: Level): Promise<any> {
    try {
      console.log(data.toJson());
      
      const res = await this.http
        .post(`${URL_SERVER}/alerta`, data.toJson(), {
          headers: this.header.jsonheader,
        })
        .toPromise();
      console.log(res);
    } catch (error) {
      console.error(error);
      sendAlert('Erro ao Inserir Alerta!', 'danger');
      throw error;
    }
  }

  async edit(data: Level): Promise<any> {
    try {
      const body = {
        where: {
          idNiveis: data.idNiveis,
        },
        desarmavel: data.desarmavel,
        cor: data.cor,
        label: data.label,
        tempo: data.tempo,
      };
      console.log(body);

      const res = await this.http
        .put(`${URL_SERVER}/alerta`, body, {
          headers: this.header.jsonheader,
        })
        .toPromise();
      console.log(res);
    } catch (error) {
      console.error(error);

      sendAlert('Erro ao Editar Alerta!', 'danger');
      throw error;
    }
  }
}
