import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { LEVELS } from 'src/app/constants/legendlevels';
import { CreatelineComponent } from 'src/app/modals/createline/createline.component';
import { EditoccurrenceComponent } from 'src/app/modals/editoccurrence/editoccurrence.component';
import { ButtonModel } from 'src/app/models/buttonmodel';
import { Floor } from 'src/app/models/floormodel';
import { Level } from 'src/app/models/levelsmodel';
import { Line } from 'src/app/models/linemodel';
import { Occurrrence } from 'src/app/models/occurrencesmodel';
import { ModalserviceService } from 'src/app/services/modalservice/modalservice.service';
import { ButtonsrepositoryService } from 'src/app/services/repository/button/buttonsrepository.service';
import { FloorrepositoryService } from 'src/app/services/repository/floor/floorrepository.service';
import { LevelsrepositoryService } from 'src/app/services/repository/levels/levelsrepository.service';
import { LinesrepositoryService } from 'src/app/services/repository/lines/linesrepository.service';
import { OccurrencesrepositoryService } from 'src/app/services/repository/occurrences/occurrencesrepository.service';
import { WebsocketService } from 'src/app/services/repository/websocket/websocket.service';
import { StoreService } from 'src/app/services/storage/store.service';
import { ArrayInterfaceToArrayClass } from 'src/app/services/uteis/arraytoarrayclass';
import { sendAlert, sleep } from 'src/app/services/utils';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  public floor: Floor = new Floor();
  public machines: Line[] = [];
  public showmenu: boolean = true;
  public legendlevels: Level[] = [];
  public buttons: ButtonModel[] = [];
  public occurrences: Occurrrence[] = [];
  public loading: boolean = false;
  public timerinstance;
  public timer;
  public subscribemachines;
  public disabled = true;

  constructor(
    public linerep: LinesrepositoryService,
    public floorrep: FloorrepositoryService,
    public levelrep: LevelsrepositoryService,
    public buttonrep: ButtonsrepositoryService,
    public occurrencerep: OccurrencesrepositoryService,
    public store: StoreService,
    private ws: WebsocketService,
    private modal: ModalserviceService
  ) {}

  ngOnInit(): void {
    this.timer = moment().format('DD/MM/YYYY HH:mm:ss');
    this.timerinstance = setInterval(
      () => (this.timer = moment().format('DD/MM/YYYY HH:mm:ss')),
      1000
    );
  }

  ngAfterViewInit() {
    this.init();
  }

  get isAdmin() {
    return this.store.isAdmin;
  }

  get containerStyle() {
    return {
      display: 'grid',
      'grid-template-columns': `calc(${this.floor.getPropWidth()} + 20px) auto`,
    };
  }

  @HostListener('window:resize', ['$event'])
  async onResize(event) {
    this.loading = true;
    await sleep(800);
    this.loading = false;
  }

  @HostListener('document:keydown', ['$event'])
  async onKeyDown(event: KeyboardEvent) {
    if (event.key == 'F5') {
      event.preventDefault();
      this.init();
    }
  }

  public async closeTable() {
    this.loading = true;
    this.floor.changeTable();
    this.machines = this.machines.map((m) => {
      m.floor = this.floor;
      return m;
    });
    await sleep(800);
    this.loading = false;
  }

  ngOnDestroy() {
    clearInterval(this.timerinstance);
    try {
      this.subscribemachines.unsubscribe();
    } catch (error) {}
  }

  async getFloor() {
    try {
      this.floor = Floor.fromJson(await this.floorrep.get());
      console.log(this.floor);
    } catch (error) {}
  }

  async getMachines() {
    try {
      this.subscribemachines = this.ws
        .messager('frontend', ['lines'])
        .subscribe(async (res) => {
          await this.setMachines();
        });
      this.setMachines();
    } catch (error) {
      console.log(error);
    }
  }

  async getLevels() {
    try {
      const levels = await this.levelrep.get();
      this.legendlevels = levels.map((item) => Level.fromJson(item));
      console.log(this.floor);
    } catch (error) {}
  }

  async getButtons() {
    try {
      this.buttons = ArrayInterfaceToArrayClass(
        await this.buttonrep.get(),
        ButtonModel
      );
      console.log(this.buttons);
    } catch (error) {}
  }

  async setMachines() {
    this.machines = ArrayInterfaceToArrayClass(
      await this.linerep.get(),
      Line,
      this.floor,
      this.legendlevels,
      this.buttons
    );
    console.log('Lines => ', this.machines);
  }

  get armed() {
    return this.machines.filter((m) => {
      return (
        this.legendlevels
          .filter((item) => Number(item.desarmavel) === 1)
          .map((item) => Number(item.idNiveis))
          .indexOf(+m.level) > -1
      );
    });
  }

  async charge() {
    await this.getLevels();
    await this.getFloor();
    await this.getButtons();
    await this.getMachines();
  }

  async init() {
    this.loading = true;
    await this.charge();
    this.loading = false;
  }

  getBackgroundTable(item) {
    try {
      const color = this.legendlevels.find((e) => e.idNiveis == item.level).cor;
      return `${color}`;
    } catch {}
  }

  async edit() {
    this.disabled = !this.disabled;
    try {
      this.subscribemachines.unsubscribe();
    } catch (error) {}
    if (this.disabled) {
      this.loading = true;
      try {
        console.log(this.machines);
        let changed = this.machines
          .filter((m) => m.changed)
          .map((m) => this.linerep.edit(m));
        await Promise.all(changed);
        await this.charge();
      } catch (error) {}
      this.loading = false;
    }
  }

  public async getOccurrences() {
    try {
      this.occurrences = ArrayInterfaceToArrayClass(
        await this.occurrencerep.getUnclosed(),
        Occurrrence
      );
      console.log('Ocorrências => ', this.occurrences);
    } catch (error) {}
  }

  async disarm(item: Line) {
    if (!this.isAdmin) return;
    this.loading = true;
    try {
      if (this.armed.find((m) => m.IDline == item.IDline) && this.disabled) {
        await this.getOccurrences();
        const oc = this.occurrences.find(
          (o) => Number(o.IDline) == Number(item.IDline)
        );
        if (oc) {
          this.modal
            .show(EditoccurrenceComponent, {
              height: '500px',
              width: '700px',
              data: {
                line: item,
                occurrence: oc,
              },
            })
            .then((_) => this.init());
        } else {
          sendAlert('Máquina sem ocorrências!', 'warning');
          this.init();
        }
      }
    } catch (error) {}
    this.loading = false;
  }

  public async new(data: Line = new Line()) {
    if (!this.isAdmin) return;
    try {
      if (!data.IDline) {
        data.floor = this.floor;
      }
      this.disabled = true;
      this.modal
        .show(CreatelineComponent, {
          height: 'auto',
          width: '900px',
          data: data,
        })
        .finally(() => {
          this.disabled = false;
        });
    } catch (error) {}
  }
}
