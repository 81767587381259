import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IMenuoption } from 'src/app/interfaces/menuinterface';
import { getLevel, LevelUser } from 'src/app/models/levelusermodel';
import { MENU_OPTIONS } from 'src/app/models/menumodel';
import { StoreService } from 'src/app/services/storage/store.service';

@Component({
  selector: 'menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  public menuoptions: IMenuoption[] = [];
  public backgroundOpen: string = '/assets/images/logo.png';
  public backgroundClose: string = '/assets/images/logomini.png';
  @ViewChild('top') top: ElementRef;
  @ViewChild('user') user: ElementRef;

  constructor(private router: Router, public store: StoreService) {
    this.menuoptions = MENU_OPTIONS.filter(
      (item) =>
        item.showinmenu && item.levelAccess <= Number(this.store.user.level)
    );
  }

  ngOnInit(): void {
    if (!this.store.menuOpened) {
      this.closeAllSubmenus();
    }
    if (this.isViewer) {
      document.documentElement.style.setProperty('--wroot', '0px');
    }
  }

  get isViewer() {
    return this.store.isViewer;
  }

  get userName() {
    return this.store.user.name;
  }

  get levelName() {
    return this.store.levelLoginName;
  }

  ngAfterViewInit() {
    this.changeMenuForm();
  }

  public isRoute(route: IMenuoption) {
    return this.router.url.replace('/', '') === route.path;
  }

  public closeAllSubmenus() {
    this.menuoptions.forEach((item) => (item.closed = true));
  }

  public go(route: IMenuoption) {
    if (route.exit) {
      this.store.clearUser();
      this.router.navigate([`/`]);
      return;
    }
    if (route.path != undefined) this.router.navigate([`/${route.path}`]);
    if (route.submenu) {
      if (!this.store.menuOpened && route.closed) {
        this.change();
      }
      route.closed = !route.closed;
    }
  }

  public changeMenuForm() {
    this.user.nativeElement.style.height = this.store.menuOpened
      ? '80px'
      : '0px';
    this.user.nativeElement.style.opacity = '1';
    if (!this.store.menuOpened) {
      document.getElementById('menu').style.width = '64px';
      document.documentElement.style.setProperty('--wroot', '64px');
    } else {
      document.getElementById('menu').style.width = '210px';
      document.documentElement.style.setProperty('--wroot', '210px');
    }
  }

  public sizeMenu(submenus: IMenuoption[]) {
    return `${submenus.length * 42}px`;
  }

  public changeUserShow() {
    if (!this.store.menuOpened) {
      this.user.nativeElement.style.height = '0px';
      this.user.nativeElement.style.opacity = '0';
    } else {
      this.user.nativeElement.style.height = '80px';
      this.user.nativeElement.style.opacity = '1';
    }
  }

  public change() {
    this.store.changeMenuPosition();
    this.changeMenuForm();
    this.changeUserShow();
    this.changebackground();
    this.closeAllSubmenus();
  }

  public changebackground() {
    this.top.nativeElement.style.opacity = '0';
    setTimeout(() => {
      this.top.nativeElement.style.opacity = '1';
    }, 200);
  }
}
