import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { getRoutes } from './models/menumodel';

console.log(getRoutes());

const routes: any = getRoutes();

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
