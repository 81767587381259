import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { TablecomponentComponent } from 'src/app/components/tablecomponent/tablecomponent.component';
import {
  ITableColumnConfig,
  ITableHeaderConfig,
} from 'src/app/components/tablecomponent/tablecomponent.constants';
import { ILevelUser } from 'src/app/interfaces/leveluserinterface';
import { IUser } from 'src/app/interfaces/usersinterface';
import { NewuserComponent } from 'src/app/modals/newuser/newuser.component';
import { LevelUser, setUsersLevel } from 'src/app/models/levelusermodel';
import { User } from 'src/app/models/usersmodel';
import { ModalserviceService } from 'src/app/services/modalservice/modalservice.service';
import { LeveluserrepositoryService } from 'src/app/services/repository/leveluser/leveluserrepository.service';
import { UsersrepositoryService } from 'src/app/services/repository/users/usersrepository.service';
import { ArrayInterfaceToArrayClass } from 'src/app/services/uteis/arraytoarrayclass';
import { Json2XLSX } from 'src/app/services/uteis/downloadXLSX';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  public loading: boolean = false;
  public user: User[] = [];
  public levels: LevelUser[] = [];
  public downloader: Json2XLSX = new Json2XLSX();

  /**
   * Configurações de table
   */
  public configTable: ITableColumnConfig[] = [
    { key: 'IDuser', label: 'ID', size: '50px', columnsize: 7.5 },
    { key: 'cracha', label: 'Nome no Crachá', size: '1fr', columnsize: 40 },
    { key: 'name', label: 'Nome', size: '1fr', columnsize: 40 },
    {
      key: 'dslevel',
      label: 'Nível de Usuário',
      size: '190px',
      columnsize: 40,
    },
    {
      key: 'email',
      label: 'Email',
      size: '190px',
      columnsize: 40,
    },
    {
      key: 'avaible',
      label: 'Usuário Ativo',
      size: '100px',
      columnsize: 50,
      alignment: 'center',
      booleanOption: {falseValue: "0", trueValue: "1"}
    },
  ];
  public TableHeaderConfig: ITableHeaderConfig = {
    background: 'var(--color-toptable)',
    color: 'var(--color-toptable-contrast)',
  };

  @ViewChild('tableComponent') tableComponent: TablecomponentComponent;

  constructor(
    private userrep: UsersrepositoryService,
    private leveluserrep: LeveluserrepositoryService,
    private modal: ModalserviceService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  @HostListener('document:keydown', ['$event'])
  async onKeyDown(event: KeyboardEvent) {
    if (event.key == 'F5') {
      event.preventDefault();
      this.init();
    }
  }

  public async init() {
    this.loading = true;
    try {
      let userdata: IUser[] = await this.userrep.get();
      let levels: ILevelUser[] = await this.leveluserrep.get();
      this.levels = levels.map((l) => LevelUser.fromJson(l));
      this.user = ArrayInterfaceToArrayClass(userdata, User);
      this.user = setUsersLevel(this.user, this.levels);
      console.log('Users => ', this.user);
      // await sleep(2000);
    } catch (error) {
      console.log('Erro => ', error);
    }
    this.loading = false;
  }

  public async new(user: User = new User()) {
    try {
      this.modal
        .show(NewuserComponent, {
          height: 'auto',
          width: '566px',
          data: user,
        })
        .then((_) => this.init());
    } catch (error) {}
  }
}
