<main id="main">
  <div class="header">
    <div class="title">Central de Controle</div>
    <div class="timer" style="width: 250px;">{{timer}}</div>
    <!-- <div class="option">
      <mat-slide-toggle [(ngModel)]="showmenu" (change)="showMenuChange()">Exibir Menu?</mat-slide-toggle>
    </div> -->
  </div>
  <div class="container" [ngStyle]="containerStyle" *ngIf="!loading" >
    <div id="containerfloor" [style.width]="floor.getPropWidth()">
      <div class="headerfloor" [ngStyle]="{'background': disabled ? 'var(--color-primary)' : 'var(--color-red)'}">
        <div class="titlefloor">Piso Principal <span *ngIf="!disabled"> (EDITANDO...)</span></div>
        <icon icon="/assets/icons/table.svg" width="60px" height="20px" (click)="closeTable()" *ngIf="disabled && !floor.withTable"></icon>
        <icon icon="/assets/icons/addline.svg" width="60px" height="20px" (click)="new()" *ngIf="disabled && isAdmin"></icon>
        <icon icon="/assets/icons/edit.svg" width="20px" height="20px" (click)="edit()" *ngIf="disabled && isAdmin"></icon>
        <icon icon="/assets/icons/save.svg" width="60px" height="20px" (click)="edit()" *ngIf="!disabled && isAdmin"></icon>
      </div>
      <div
        class="floor"
        [style.width]="floor.getPropWidth()"
        [style.height]="floor.getPropHeight()"
        [style.background] = "floor.backgroundURL"
      >
        <squarecomponent
          *ngFor="let item of machines"
          [machine]="item"
          [color]="getBackgroundTable(item)"
          [blockmove]="disabled || item.desarmavel == '0'"
          [rounded]="item.rounded"
          (click)="disarm(item)"
          (dblclick)=" new(item)"
        ></squarecomponent>
      </div>
      <div class="footerfloor">
        <div class="level" *ngFor="let legend of legendlevels">
          <div
            class="colorlevel"
            [style.backgroundColor]="legend.cor"
          ></div>
          <div class="labellevel">{{ legend.label }}</div>
        </div>
      </div>
    </div>
    <div id="tablefloor">
      <div class="table" [style.height]="'calc('+floor.getPropHeight()+' + 60px)'">
        <div class="toptable">
          <div class="label c">
            <div class="text" style="width: 100%;">Eventos Acionados</div>
            <div class="close" style="width: 20px; cursor: pointer" (click)="closeTable()">X</div>
          </div>
        </div>
        <div class="subtoptable" table="true" style="--cl: 50px 1fr">
          <div class="label c">Nível</div>
          <div class="label c">Setor</div>
        </div>
        <div class="bodytable blinker" table="true" style="--cl: 50px 1fr" *ngFor="let item of armed" [ngStyle]="{'background': getBackgroundTable(item)}" (click)="disarm(item)">
          <div class="label c">{{item.level}}</div>
          <div class="label c">{{item.label}}</div>
        </div>
      </div>
    </div>
  </div>
</main>
<menu #menu></menu>
<spinner *ngIf="loading">Carregando...</spinner>
