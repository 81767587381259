import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IReason } from 'src/app/interfaces/reasonsinterface';
import { ISector } from 'src/app/interfaces/sectorinterface';
import { CloseOccurrences } from 'src/app/models/closeoccurrencesmodel';
import { Line } from 'src/app/models/linemodel';
import { Occurrrence } from 'src/app/models/occurrencesmodel';
import { OccurrencesrepositoryService } from 'src/app/services/repository/occurrences/occurrencesrepository.service';
import { ReasonsrepositoryService } from 'src/app/services/repository/reasons/reasonsrepository.service';
import { SectorrepositoryService } from 'src/app/services/repository/sectors/sectorrepository.service';
import { StoreService } from 'src/app/services/storage/store.service';
import { sendAlert } from 'src/app/services/utils';

@Component({
  selector: 'app-editoccurrence',
  templateUrl: './editoccurrence.component.html',
  styleUrls: ['./editoccurrence.component.scss'],
})
export class EditoccurrenceComponent implements OnInit {
  @Input() title: string = 'Alterando Ocorrência';
  @Input() line: Line;
  @Input() occurrence: Occurrrence;
  public loading: boolean = false;
  public reasons: IReason[] = [];
  public sectors: ISector[] = [];
  public closeOccurrence: CloseOccurrences = new CloseOccurrences();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { line: Line; occurrence: Occurrrence },
    public dialogRef: MatDialogRef<EditoccurrenceComponent>,
    private reasonrep: ReasonsrepositoryService,
    private sectorrep: SectorrepositoryService,
    private occurrrencerep: OccurrencesrepositoryService,
    private store: StoreService
  ) {}

  ngOnInit(): void {
    this.getOptions();
    this.line = this.data.line;
    this.occurrence = this.data.occurrence;
    console.log('Linha localizada => ', this.line);
    console.log('Ocorrencia localizada => ', this.occurrence);

    this.closeOccurrence.IDline = this.line.IDline;
    this.closeOccurrence.IDoccurrences = this.occurrence.IDoccurrences;
    this.closeOccurrence.IDreasonOff = this.occurrence.IDreasonOff;
    this.closeOccurrence.IDsector = this.occurrence.IDsector;
    this.closeOccurrence.text = this.occurrence.happen;
    console.log(this.closeOccurrence);
  }

  async getOptions() {
    this.loading = true;
    try {
      this.reasons = await this.reasonrep.get();
      this.sectors = await this.sectorrep.get();
    } catch (error) {}
    this.loading = false;
  }

  validate() {
    if (this.closeOccurrence.pin && this.closeOccurrence.pin != this.store.user.pin) {
      sendAlert('PIN não corresponde ao usuário logado, verifique!', 'warning');
      return false;
    }
    if(!this.closeOccurrence.IDreasonOff){
      sendAlert('Motivo da parada é campo obrigatório!', 'warning');
      return false;
    }
    if(!this.closeOccurrence.IDsector){
      sendAlert('Setor a ser acionado é campo obrigatório!', 'warning');
      return false;
    }
    if(!this.closeOccurrence.text){
      sendAlert('Informações é campo obrigatório!', 'warning');
      return false;
    }
    return true;
  }

  async send() {
    if (!this.validate()) return;
    this.loading = true;
    try {
      const resp = await this.occurrrencerep.close(this.closeOccurrence);
      console.log(resp);
      this.dialogRef.close(this.closeOccurrence);
    } catch (error) {}
    this.loading = false;
  }
}
