import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IFloor } from 'src/app/interfaces/floorinterface';
import { environment } from 'src/environments/environment';
import { HeaderJson } from '../headers/jsonheader';

const URL_SERVER = environment.url_server;

@Injectable({
  providedIn: 'root',
})
export class FloorrepositoryService {
  private header:HeaderJson = new HeaderJson();

  constructor(private http: HttpClient) {
  }

  async get(): Promise<IFloor> {
    try {
      // const res = await this.http.get(`${URL_SERVER}/floor`).toPromise();
      const res = await this.http.get("/assets/api/floor.json").toPromise();
      return res as IFloor;
    } catch (error) {
      console.error(error);
      return {} as IFloor;
    }
  }


}
