<main id="main">
  <div class="header">
    <div class="title">Relatório de Ocorrências</div>
  </div>
  <div class="container">
    <div class="formsearch">
      <div class="itemsearch" style="width: 260px">
        <div class="label">Data Inicial:</div>
        <div class="item">
          <datepicker #dtstart [(value)]="formOccurrence.initDate"></datepicker>
        </div>
      </div>
      <div class="itemsearch" style="width: 260px">
        <div class="label">Data Final:</div>
        <div class="item">
          <datepicker #dtend [(value)]="formOccurrence.endDate"></datepicker>
        </div>
      </div>
      <div class="itemsearch" style="width: 260px">
        <div class="label"></div>
        <div class="item">
          <buttoncomponent
            text="Pesquisar"
            background="var(--color-toptable)"
            (onClick)="getOccurrences()"
          ></buttoncomponent>
        </div>
      </div>
    </div>
    <div class="bodyreport" *ngIf="occurrences.length > 0">
      <tablecomponent
        #tableComponent
        [columnsConfig]="configTable"
        [values]="occurrences"
        [headerConfig]="TableHeaderConfig"
        [download]="true"
        (onDownload)="downloadTable($event)"
        (onRowClick)="rowClicked($event)"
        title="Ocorrências Registradas"
        *ngIf="!loading"
        height="30vh"
      ></tablecomponent>
      <div class="charts">
        <barchart
          xlabel="x"
          ylabel="y"
          title="Tempo Abertura/Fechamento Ocorrências (Minutos)"
          [data]="occurrences_calc.timeByLine"
          idgraph="teste2"
          *ngIf="!loading"
          rotate="20"
          [labelpos]="['left', 'top']"
        ></barchart>
        <piechart
          title="Ocorrências por Linha"
          [data]="occurrences_calc.occurrencesByLine"
          idgraph="teste1"
          *ngIf="!loading"
        ></piechart>
      </div>
    </div>
  </div>
  <div class="container nodata" *ngIf="occurrences.length == 0">
    <div class="message">Nenhuma ocorrência localizada...</div>
  </div>
</main>
<menu #menu></menu>
<spinner *ngIf="loading">Carregando...</spinner>
