import * as moment from 'moment';
import { ITableColumnConfig } from '../components/tablecomponent/tablecomponent.constants';
import { XLSXdownloader } from '../services/uteis/xlsxdownloader.service';
import { Occurrrence } from './occurrencesmodel';

export class OcurrencesCalc {
  private _occurrences: Occurrrence[] = [];

  constructor(occurrences: Occurrrence[] = []) {
    if (occurrences.length > 0) this.occurrences = occurrences;
  }

  set occurrences(values: Occurrrence[]) {
    this._occurrences = values;
  }

  get occurrences() {
    return this._occurrences;
  }

  get occurrencesByLine() {
    let counter = {};
    for (let o of this.occurrences) {
      const label = `${o.line} (${o.IDline})`;
      counter[label] = (counter[label] || 0) + 1;
    }
    return Object.keys(counter).map((item: any) => {
      return { x: item, y: counter[item] };
    });
  }

  get timeByLine() {
    let counter = {};
    for (let o of this.occurrences) {
      const label = `${o.line} (${o.IDline})`;
      const timer = moment(
        o.dataButtonOff + ' ' + o.timeButtonOff,
        'DD/MM/YYYY HH:mm:ss'
      ).diff(moment(o.dataStart + ' ' + o.timeStart, 'DD/MM/YYYY HH:mm:SS'), "minutes");
      counter[label] = (counter[label] || 0) + timer;
    }
    return Object.keys(counter).map((item: any) => {
      return { x: item, y: counter[item] };
    });
  }
}
