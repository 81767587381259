<div id="selectcomponent" [style.margin]="margin">
  <input
    [(ngModel)]="value"
    disabled
    (change)="onChange()"
    (focus)="focused = true"
    (blur)="focused = false"
    type="text"
    autocomplete="new-password"
  />
  <div class="arrowselectcomponent" [ngStyle]="{'transform': showoptions ? 'rotate(180deg)' : 'unset'}"></div>
  <div
    class="selectcomponenthittext"
    *ngIf="hinttext"
    [ngClass]="{ selectcomponenthittextfocused: focused || value }"
  >
    {{ hinttext }}
  </div>
  <div class="selectarea" *ngIf="showoptions">
    <div
      class="itemselectarea"
      (click)="onSelect(item)"
      *ngFor="let item of data"
    >
      {{ item[labelkey] }} ({{ item[idkey] }})
    </div>
  </div>
</div>
