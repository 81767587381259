import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IButton } from 'src/app/interfaces/buttoninterface';
import { ButtonModel } from 'src/app/models/buttonmodel';
import { environment } from 'src/environments/environment';
import { sendAlert } from '../../utils';
import { HeaderJson } from '../headers/jsonheader';

const URL_SERVER = environment.url_server;

@Injectable({
  providedIn: 'root'
})
export class ButtonsrepositoryService {
  private header: HeaderJson = new HeaderJson();

  constructor(private http: HttpClient) {}

  async get(): Promise<IButton[]> {
    try {
      const res = await this.http
        .get(`${URL_SERVER}/button`, { headers: this.header.jsonheader })
        .toPromise();
      // const res = await this.http.get('/assets/api/buttons.json').toPromise();
      return res as IButton[];
    } catch (error) {
      console.error(error);
      sendAlert('Erro ao Consultar Usuário!', 'danger');
      return [];
    }
  }

  // async set(data: ButtonModel): Promise<any> {
  //   try {
  //     const res = await this.http
  //       .post(`${URL_SERVER}/button`, data.toJson(), {
  //         headers: this.header.header,
  //       })
  //       .toPromise();
  //     console.log(res);
  //   } catch (error) {
  //     console.error(error);
  //     sendAlert('Erro ao Inserir Usuário!', 'danger');
  //     throw(error);
  //   }
  // }

  // async edit(data: ButtonModel): Promise<any> {
  //   try {

  //     const res = await this.http
  //       .put(`${URL_SERVER}/button`, data.toJsonEdit(), {
  //         headers: this.header.header,
  //       })
  //       .toPromise();
  //     console.log(res);
  //   } catch (error) {
  //     console.error(error);

  //     sendAlert('Erro ao Editar Usuário!', 'danger');
  //     throw(error);
  //   }
  // }
}
