<main id="main">
  <div class="header">
    <div class="title">Usuários</div>
    <div class="menuicon" (click)="new()">
      <icon icon="/assets/icons/addnew.svg" width="32px" height="32px"></icon>
    </div>
  </div>
  <div class="container">
    <tablecomponent
      #tableComponent
      [columnsConfig]="configTable"
      [values]="user"
      [headerConfig]="TableHeaderConfig"
      [download]="true"
      (onDownload)="downloader.download($event, configTable)"
      (onRowClick)="new($event)"
      title="Lista de Usuários Cadastrados"
      *ngIf="!loading"
      height="88vh"
    ></tablecomponent>
  </div>
</main>
<menu #menu></menu>
<spinner *ngIf="loading">Carregando...</spinner>
