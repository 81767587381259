import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ILogin } from 'src/app/interfaces/logininterface';
import { LoginModel } from 'src/app/models/loginmodel';
import { environment } from 'src/environments/environment';
import { sendAlert } from '../../utils';
import { HeaderJson } from '../headers/jsonheader';

const URL_SERVER = environment.url_server;

@Injectable({
  providedIn: 'root',
})
export class LoginrepositoryService {
  private header: HeaderJson = new HeaderJson();

  constructor(private http: HttpClient) {}

  async login(formdata: LoginModel): Promise<ILogin> {
    try {
      const res = await this.http
        .post(`${URL_SERVER}/login`, formdata.toJson(), {
          headers: this.header.jsonheader,
        })
        .toPromise();
        console.log("[LOGIN] response => ", res);
        
      return res as ILogin;
    } catch (error) {
      console.error(error);
      sendAlert('Erro ao Realizar Login!', 'danger');
      return {} as ILogin;
    }
  }
}
