import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { TablecomponentComponent } from 'src/app/components/tablecomponent/tablecomponent.component';
import {
  ITableColumnConfig,
  ITableHeaderConfig,
} from 'src/app/components/tablecomponent/tablecomponent.constants';
import { CreatelineComponent } from 'src/app/modals/createline/createline.component';
import { Floor } from 'src/app/models/floormodel';
import { Level } from 'src/app/models/levelsmodel';
import { Line } from 'src/app/models/linemodel';
import { ModalserviceService } from 'src/app/services/modalservice/modalservice.service';
import { FloorrepositoryService } from 'src/app/services/repository/floor/floorrepository.service';
import { LevelsrepositoryService } from 'src/app/services/repository/levels/levelsrepository.service';
import { LinesrepositoryService } from 'src/app/services/repository/lines/linesrepository.service';
import { ArrayInterfaceToArrayClass } from 'src/app/services/uteis/arraytoarrayclass';
import { Json2XLSX } from 'src/app/services/uteis/downloadXLSX';

@Component({
  selector: 'app-lines',
  templateUrl: './lines.component.html',
  styleUrls: ['./lines.component.scss'],
})
export class LinesComponent implements OnInit {
  public loading: boolean = false;
  public data: Line[] = [];
  public levels: Level[] = [];
  public downloader: Json2XLSX = new Json2XLSX();
  public floor: Floor = new Floor();

  /**
   * Configurações de table
   */
  public configTable: ITableColumnConfig[] = [
    {
      key: 'signal',
      signal: { color: ['leveldata', 'cor'] },
      label: 'Status',
      size: '52px',
      alignment: 'center',
    },
    {
      key: 'IDline',
      label: 'ID',
      size: '50px',
      columnsize: 7.5,
      alignment: 'center',
    },
    { key: 'label', label: 'Linha', size: '1fr', columnsize: 40 },
    {
      key: 'level',
      label: 'Nível',
      size: '100px',
      columnsize: 20,
      alignment: 'center',
    },
    {
      key: ['leveldata', 'label'],
      label: 'Nível Descrição',
      size: '200px',
    },
  ];
  public TableHeaderConfig: ITableHeaderConfig = {
    background: 'var(--color-toptable)',
    color: 'var(--color-toptable-contrast)',
  };

  @ViewChild('tableComponent') tableComponent: TablecomponentComponent;

  constructor(
    private linerep: LinesrepositoryService,
    private levelrep: LevelsrepositoryService,
    private floorrep: FloorrepositoryService,
    private modal: ModalserviceService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  @HostListener('document:keydown', ['$event'])
  async onKeyDown(event: KeyboardEvent) {
    if (event.key == 'F5') {
      event.preventDefault();
      this.init();
    }
  }

  public async init() {
    this.loading = true;
    try {
      this.levels = ArrayInterfaceToArrayClass(
        await this.levelrep.get(),
        Level
      );
      this.floor = Floor.fromJson(await this.floorrep.get());
      this.data = ArrayInterfaceToArrayClass(
        await this.linerep.get(),
        Line,
        this.floor,
        this.levels
      );
      console.log('Lines => ', this.data);
      console.log('Levels => ', this.levels);
      // await sleep(2000);
    } catch (error) {
      console.log('Erro => ', error);
    }
    this.loading = false;
  }

  public async new(data: Line = new Line()) {
    try {
      if (!data.IDline) {
        data.floor = this.floor;
      }
      this.modal
        .show(CreatelineComponent, {
          height: 'auto',
          width: '900px',
          data: data,
        })
        .then((_) => this.init());
    } catch (error) {}
  }
}
