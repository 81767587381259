import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Level } from 'src/app/models/levelsmodel';
import { LevelsrepositoryService } from 'src/app/services/repository/levels/levelsrepository.service';
import { sendAlert } from 'src/app/services/utils';

@Component({
  selector: 'app-newalert',
  templateUrl: './newalert.component.html',
  styleUrls: ['./newalert.component.scss'],
})
export class NewalertComponent implements OnInit {
  @Input() title: string = 'Inserindo Alerta (Nível)';
  @Input() values: Level = new Level();
  public loading: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Level,
    public dialogRef: MatDialogRef<NewalertComponent>,
    private alertrep: LevelsrepositoryService
  ) {}

  ngOnInit(): void {
    if (this.data.idNiveis) {
      this.values = this.data;
      this.title = 'Editando Alerta (Nível)';
    }
    console.log(this.values);
    
  }

  changeDesarmavel(value: boolean) {
    this.values.desarmavel = value ? '1' : '0';
  }

  async send() {
    this.loading = true;
    try {
      if (this.data.idNiveis) await this.alertrep.edit(this.values);
      else await this.alertrep.set(this.values);
      this.dialogRef.close(this.values);
    } catch (error) {
      sendAlert('Erro ao Inserir/Editar Setor!', 'danger');
    }
    this.loading = false;
  }
}
