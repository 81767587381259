import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { LeveluserrepositoryService } from './services/repository/leveluser/leveluserrepository.service';
import { StoreService } from './services/storage/store.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private store: StoreService,
    private router: Router,
    private levelrep: LeveluserrepositoryService
  ) {}

  ngOnInit(): void {
    this.levelrep.get().then((res) => {
      this.store.levels = res;
    });
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationStart) {
        if (!this.store.user.level && evt.url != '/') {
          this.router.navigate([`/`]);
        } else if (this.store.isViewer && evt.url != '/home') {
          this.router.navigate([`/home`]);
        }
      }
    });
  }
}
