<main id="main">
    <div class="header">
      <div class="title">Sobre a Empresa</div>
      <!-- <div class="option">
        <mat-slide-toggle [(ngModel)]="showmenu" (change)="showMenuChange()">Exibir Menu?</mat-slide-toggle>
      </div> -->
    </div>
    <div class="container">
      
    </div>
  </main>
  <menu #menu></menu>
  