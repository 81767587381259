export class CloseOccurrences {
  public pin: string;
  public IDoccurrences: string;
  public IDsector: string;
  public IDreasonOff: string;
  public IDline: string;
  public text: string;

  public toJson() {
    return {
      pin: this.pin,
      IDoccurrences: this.IDoccurrences,
      IDsector: this.IDsector,
      IDreasonOff: this.IDreasonOff,
      IDline: this.IDline,
      text: this.text,
    };
  }
}
