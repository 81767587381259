<main id="login">
  <div class="loginscreen">
    <form class="form" autocomplete="off">
      <div class="logo"></div>
      <inputcomponent
        hinttext="Login"
        marginBottom="4px"
        [(data)]="formdata.user"
        type="text"
        style="width: 80%"
      ></inputcomponent>
      <inputcomponent
        hinttext="Senha"
        marginBottom="4px"
        [(data)]="formdata.password"
        (onEnter)="login()"
        type="password"
        style="width: 80%"
      ></inputcomponent>
      <br />
      <div class="buttonlogin" (click)="login()">Entrar</div>
    </form>
  </div>
  <div class="tileback"></div>
</main>
<spinner *ngIf="loading">Carregando...</spinner>
