<section id="modal">
  <div class="headermodal">
    <div class="titlemodal">{{ title }}</div>
    <icon
      icon="/assets/icons/closeicon.svg"
      width="20px"
      height="20px"
      class="iconmodal"
      mat-dialog-close
    ></icon>
  </div>
  <div class="bodymodal">
    <div class="structmodal">
      <div class="formdata">
        <inputcomponent
          hinttext="Nível"
          marginBottom="4px"
          [(data)]="values.idNiveis"
          [disabled]="data.idNiveis"
          type="number"
          style="width: 20%"
        ></inputcomponent>
        <inputcomponent
          hinttext="Descrição"
          marginBottom="4px"
          [(data)]="values.label"
          type="text"
          style="width: 80%"
        ></inputcomponent>
        <inputcomponent
          hinttext="Cor"
          marginBottom="4px"
          [(data)]="values.cor"
          type="color"
          style="width: 20%"
        ></inputcomponent>
        <inputcomponent
          hinttext="Timer"
          marginBottom="4px"
          [(data)]="values.tempo"
          type="number"
          style="width: 40%"
        ></inputcomponent>
        <switch style="width: 40%" [data]="values.desarmavel == '1'" (onChange)="changeDesarmavel($event)"
          >Desarmável?</switch
        >
      </div>
      <div style="float: right; margin-top: 12px; margin-bottom: 8px">
        <buttoncomponent
          text="Salvar"
          background="var(--color-primary)"
          (onClick)="send()"
        ></buttoncomponent>
      </div>
    </div>
    <spinner *ngIf="loading">Carregando...</spinner>
  </div>
</section>
