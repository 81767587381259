import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { TablecomponentComponent } from 'src/app/components/tablecomponent/tablecomponent.component';
import {
  ITableColumnConfig,
  ITableHeaderConfig,
} from 'src/app/components/tablecomponent/tablecomponent.constants';
import { ButtonModel } from 'src/app/models/buttonmodel';
import { ButtonsrepositoryService } from 'src/app/services/repository/button/buttonsrepository.service';
import { LinesrepositoryService } from 'src/app/services/repository/lines/linesrepository.service';
import { ArrayInterfaceToArrayClass } from 'src/app/services/uteis/arraytoarrayclass';
import { Json2XLSX } from 'src/app/services/uteis/downloadXLSX';

@Component({
  selector: 'app-buttons',
  templateUrl: './buttons.component.html',
  styleUrls: ['./buttons.component.scss'],
})
export class ButtonsComponent implements OnInit {
  public loading: boolean = false;
  public data: ButtonModel[] = [];
  public downloader: Json2XLSX = new Json2XLSX();

  /**
   * Configurações de table
   */
  public configTable: ITableColumnConfig[] = [
    {
      key: 'status',
      label: 'Status',
      size: '100px',
      columnsize: 50,
      alignment: 'center',
      booleanOption: { falseValue: 'off', trueValue: 'on' },
    },
    {
      key: 'IDbutton',
      label: 'ID Botão',
      size: '100px',
      columnsize: 7.5,
      alignment: 'center',
    },
    {
      key: ['linedata','IDline'],
      label: 'ID Linha',
      size: '100px',
      columnsize: 7.5,
      alignment: 'center',
    },
    {
      key: ['linedata','label'],
      label: 'Linha',
      size: '1fr',
      columnsize: 7.5,
      alignment: 'left',
    },
  ];
  public TableHeaderConfig: ITableHeaderConfig = {
    background: 'var(--color-toptable)',
    color: 'var(--color-toptable-contrast)',
  };

  @ViewChild('tableComponent') tableComponent: TablecomponentComponent;

  constructor(private buttonrep: ButtonsrepositoryService,private linerep: LinesrepositoryService) {}

  ngOnInit(): void {
    this.init();
  }

  @HostListener('document:keydown', ['$event'])
  async onKeyDown(event: KeyboardEvent) {
    if (event.key == 'F5') {
      event.preventDefault();
      this.init();
    }
  }

  public async init() {
    this.loading = true;
    try {
      this.data = ArrayInterfaceToArrayClass(
        await this.buttonrep.get(),
        ButtonModel,
        await this.linerep.get()
      );
      console.log('Buttons => ', this.data);
      // await sleep(2000);
    } catch (error) {
      console.log('Erro => ', error);
    }
    this.loading = false;
  }

  // public async new(data: ButtonModel = new ButtonModel()) {
  //   try {
  //     this.modal
  //       .show(CreatereasonComponent, {
  //         height: 'auto',
  //         width: '600px',
  //         data: data,
  //       })
  //       .then((_) => {
  //         this.init();
  //       });
  //   } catch (error) {}
  // }
}
