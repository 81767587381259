import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'textareacomponent',
  templateUrl: './textareacomponent.component.html',
  styleUrls: ['./textareacomponent.component.scss'],
})
export class TextareacomponentComponent implements OnInit {
  @Input() rows: string = '8';
  @Input() margin: string = '2px';
  @Input() hinttext: string;
  @Input() resize: string="none";
  @Input() data: string;
  @Output() dataChange = new EventEmitter<string>();
  public focused = false;

  constructor() {}

  ngOnInit(): void {}

  public onChange() {
    this.dataChange.emit(this.data);
  }
}
