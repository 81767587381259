import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IReason } from 'src/app/interfaces/reasonsinterface';
import { HeaderJson } from '../headers/jsonheader';
import { environment } from 'src/environments/environment';
import { Reason } from 'src/app/models/reasonmodel';
import { sendAlert } from '../../utils';

const URL_SERVER = environment.url_server;

@Injectable({
  providedIn: 'root',
})
export class ReasonsrepositoryService {
  private header: HeaderJson = new HeaderJson();

  constructor(private http: HttpClient) {}

  async get(): Promise<IReason[]> {
    try {
      const res = await this.http
        .get(`${URL_SERVER}/reason`, { headers: this.header.jsonheader })
        .toPromise();
      // const res = await this.http.get("/assets/api/reasons.json").toPromise();
      return res as IReason[];
    } catch (error) {
      console.error(error);
      sendAlert('Erro ao Consultar Motivos de Parada!', 'danger');
      return [];
    }
  }

  async set(reason: Reason): Promise<any> {
    try {
      const res = await this.http
        .post(`${URL_SERVER}/reason`, reason.toJson(), {
          headers: this.header.jsonheader,
        })
        .toPromise();
      console.log(res);
    } catch (error) {
      console.error(error);
      sendAlert('Erro ao Inserir Motivos de Parada!', 'danger');
      throw(error);
    }
  }

  async edit(reason: Reason): Promise<any> {
    try {
      const body = {
        where: {
          IDreasonOff: reason.IDreasonOff,
        },
        reasonOff: reason.reasonOff,
      };

      const res = await this.http
        .put(`${URL_SERVER}/reason`, body, {
          headers: this.header.jsonheader,
        })
        .toPromise();
      console.log(res);
    } catch (error) {
      console.error(error);
      sendAlert('Erro ao Editar Motivos de Parada!', 'danger');
      throw(error);
    }
  }
}
