import { IButton } from '../interfaces/buttoninterface';
import { Line } from './linemodel';

export class ButtonModel implements IButton {
  IDbutton: string;
  ButtonHashOn?: string;
  ButtonHashOff?: string;
  status?: string;
  linedata?: Line;

  public static fromJson(js: IButton, lines: Line[] = []) {
    let button = new ButtonModel();
    button.IDbutton = js.IDbutton;
    button.ButtonHashOn = js.ButtonHashOn;
    button.ButtonHashOff = js.ButtonHashOff;
    button.status = js.status;
    if (lines.length > 0) {
      button.linedata = lines.find(
        (l) => Number(l.IDbutton) == Number(button.IDbutton)
      );
    }else{
      button.linedata = new Line();
    }
    return button;
  }

  public toJson() {
    return {
      IDbutton: this.IDbutton,
      ButtonHashOn: this.ButtonHashOn,
      ButtonHashOff: this.ButtonHashOff,
      status: this.status,
    };
  }
}
