import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LEVELS_USERS } from 'src/app/constants/levels_users';
import { ILevelUser } from 'src/app/interfaces/leveluserinterface';
import { HeaderJson } from '../headers/jsonheader';

@Injectable({
  providedIn: 'root',
})
export class LeveluserrepositoryService {
  private header: HeaderJson = new HeaderJson();

  constructor(private http: HttpClient) {}

  async get(): Promise<ILevelUser[]> {
    try {
      // const res = await this.http.get(`${URL_SERVER}/lines`, {headers: this.header.header}).toPromise();
      return LEVELS_USERS;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
}
