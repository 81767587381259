<section id="modal">
  <div class="headermodal">
    <div class="titlemodal">{{ title }}</div>
    <icon
      icon="/assets/icons/closeicon.svg"
      width="20px"
      height="20px"
      class="iconmodal"
      mat-dialog-close
    ></icon>
  </div>
  <div class="bodymodal">
    <div class="formdata">
      <inputcomponent
        hinttext="ID"
        marginBottom="4px"
        [(data)]="user.IDuser"
        type="text"
        style="width: 20%"
        disabled="true"
      ></inputcomponent>
      <inputcomponent
        hinttext="Nome do Usuário"
        marginBottom="4px"
        [(data)]="user.name"
        type="text"
        style="width: 80%"
      ></inputcomponent>
      <inputcomponent
        hinttext="Email"
        marginBottom="4px"
        [(data)]="user.email"
        type="email"
        style="width: 50%"
      ></inputcomponent>
      <inputcomponent
        hinttext="Telegram"
        marginBottom="4px"
        [(data)]="user.telegram"
        type="phone"
        style="width: 50%"
      ></inputcomponent>
      <inputcomponent
        hinttext="Texto do Crachá"
        marginBottom="4px"
        [(data)]="user.cracha"
        type="text"
        style="width: 100%"
      ></inputcomponent>
      <inputcomponent
        hinttext="Nível de Acesso"
        marginBottom="4px"
        [(data)]="user.level"
        type="number"
        style="width: 30%"
      ></inputcomponent>
      <inputcomponent
        hinttext="Descrição do Nìvel"
        marginBottom="4px"
        [data]="dslevel"
        disabled="true"
        type="text"
        style="width: 70%"
      ></inputcomponent>
      <inputcomponent
        hinttext="PIN"
        marginBottom="4px"
        [(data)]="user.pin"
        [disabled]="user.IDuser"
        type="text"
        style="width: 50%"
      ></inputcomponent>
      <inputcomponent
        hinttext="Usuário"
        marginBottom="4px"
        [(data)]="user.user"
        type="text"
        [disabled]="user.IDuser"
        style="width: 50%"
      ></inputcomponent>
      <inputcomponent
        hinttext="Senha"
        marginBottom="4px"
        [(data)]="user.password"
        type="password"
        [disabled]="user.IDuser"
        style="width: 50%"
      ></inputcomponent>
      <inputcomponent
        hinttext="Repetir Senha"
        marginBottom="4px"
        [(data)]="password_validation"
        type="password"
        style="width: 50%"
        [disabled]="user.IDuser"
        [error]="
          user.password && user.password != password_validation
            ? 'Senhas não correspondem!'
            : ''
        "
      ></inputcomponent>
      <billboard
        title="Configurações"
        height="45px"
        width="100%"
        style="width: 100%"
      >
        <switch
          [data]="user.avaible == '1'"
          (onChange)="user.avaible = $event ? '1' : '0'"
          >Usuário ativo?</switch
        >
      </billboard>
    </div>
    <div style="float: right">
      <buttoncomponent
        text="Salvar"
        background="var(--color-primary)"
        (onClick)="send()"
      ></buttoncomponent>
    </div>
  </div>
  <!-- <spinner *ngIf="loading">Carregando...</spinner> -->
</section>
