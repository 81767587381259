import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { DatepickerComponent } from 'src/app/components/datepicker/datepicker.component';
import { TablecomponentComponent } from 'src/app/components/tablecomponent/tablecomponent.component';
import {
  ITableColumnConfig,
  ITableHeaderConfig,
} from 'src/app/components/tablecomponent/tablecomponent.constants';
import {
  IGetOccurrence,
  IOccurrence,
} from 'src/app/interfaces/occurrencesinterface';
import { OcurrencesCalc } from 'src/app/models/occurrencescalcmodel';
import { GetOccurrence, Occurrrence } from 'src/app/models/occurrencesmodel';
import { OccurrencesrepositoryService } from 'src/app/services/repository/occurrences/occurrencesrepository.service';
import { Json2XLSX } from 'src/app/services/uteis/downloadXLSX';
import { sleep } from 'src/app/services/utils';

@Component({
  selector: 'app-occurrencespage',
  templateUrl: './occurrencespage.component.html',
  styleUrls: ['./occurrencespage.component.scss'],
})
export class OccurrencespageComponent implements OnInit {
  public occurrences: Occurrrence[] = [];
  public occurrences_calc: OcurrencesCalc = new OcurrencesCalc();
  public loading: boolean = false;
  public configTable: ITableColumnConfig[] = [
    { key: 'IDoccurrences', label: 'ID', size: '50px', columnsize: 7.5 },
    { key: 'line', label: 'Linha', size: '150px', columnsize: 23 },
    {
      key: 'IDbutton',
      label: 'Botoeira',
      size: '150px',
      columnsize: 23,
      alignment: 'center',
    },
    { key: 'reason', label: 'Razão', size: '1fr', columnsize: 40 },
    { key: 'sector', label: 'Setor', size: '1fr', columnsize: 20 },
    { key: 'dataStart', label: 'Data Início', size: '100px', columnsize: 15 },
    {
      key: 'timeStart',
      label: 'Horário Início',
      size: '100px',
      columnsize: 15,
    },
    {
      key: 'dataButtonOff',
      label: 'Data Encerramento',
      size: '100px',
      columnsize: 18,
    },
    {
      key: 'timeButtonOff',
      label: 'Horário Encerramento',
      size: '100px',
      columnsize: 18,
    },
  ];
  public TableHeaderConfig: ITableHeaderConfig = {
    background: 'var(--color-toptable)',
    color: 'var(--color-toptable-contrast)',
  };

  @ViewChild('tableComponent') tableComponent: TablecomponentComponent;
  @ViewChild('dtstart') dtstart: DatepickerComponent;
  @ViewChild('dtend') dtend: DatepickerComponent;

  public formOccurrence: GetOccurrence = GetOccurrence.fromJson({
    endDate: moment().format('DD/MM/YYYY'),
    initDate: moment().subtract(7, 'days').format('DD/MM/YYYY'),
  });

  constructor(private occurrencesrep: OccurrencesrepositoryService) {}

  ngOnInit(): void {
    this.getOccurrences();
  }

  @HostListener('document:keydown', ['$event'])
  async onKeyDown(event: KeyboardEvent) {
    if (event.key == 'F5') {
      event.preventDefault();
      this.getOccurrences();
    }
  }

  async getOccurrences() {
    this.loading = true;
    try {
      console.log(this.formOccurrence.toJson());
      const occurrences = await this.occurrencesrep.get(
        this.formOccurrence.toJson()
      );
      this.occurrences = occurrences.map((occurrence: IOccurrence) =>
        Occurrrence.fromJson(occurrence)
      );
      console.log('Ocorrências => ', this.occurrences);
      this.occurrences_calc.occurrences = this.occurrences;
      console.log(this.occurrences_calc.timeByLine);
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  }

  async downloadTable(value) {
    console.log('downloaded => ', value);
    new Json2XLSX().download(value, this.configTable, 'Ocorrencias');
  }

  async rowClicked(value) {
    console.log('row => ', value);
  }
}
