import { IFloor } from '../interfaces/floorinterface';
import { ILine } from '../interfaces/lineInterface';
import { ButtonModel } from './buttonmodel';
import { Floor } from './floormodel';
import { Level } from './levelsmodel';

export class Line implements ILine {
  IDline: string;
  IDbutton: string;
  label: string = '';
  level: string;
  leveldata: Level = new Level();
  buttondata: ButtonModel = new ButtonModel();
  posx: string = '0';
  posy: string = '0';
  width: string = '10';
  height: string = '10';
  rounded: boolean = false;
  textOrientation: string = 'horizontal';
  floor: Floor;
  changed: boolean = false;

  constructor() {}

  static fromJson(
    props: ILine,
    floor: Floor = new Floor(),
    levels: Level[] = [],
    buttons: ButtonModel[] = []
  ) {
    let obj = new Line();
    obj.IDline = props.IDline;
    obj.IDbutton = props.IDbutton;
    obj.label = props.label;
    obj.level = props.level;
    obj.posx = props.posx;
    obj.posy = props.posy;
    obj.width = props.width;
    obj.height = props.height;
    obj.rounded = props.rounded;
    obj.textOrientation = props.textOrientation || obj.textOrientation;
    obj.floor = floor;
    try {
      if (levels.length > 0) {
        obj.leveldata =
          levels.find((l) => Number(l.idNiveis) == Number(obj.level)) ||
          new Level();
      }
    } catch (error) {
      obj.leveldata = new Level();
    }
    try {
      if (buttons.length > 0) {
        obj.buttondata =
          buttons.find((l) => Number(l.IDbutton) == Number(obj.IDbutton)) ||
          new ButtonModel();
      }
    } catch (error) {
      obj.buttondata = new ButtonModel();
    }
    return obj;
  }

  get menuDiscont() {
    const res = getComputedStyle(document.body).getPropertyValue('--wroot');
    const value = res.split('px');
    return Number(value[0]);
  }

  get getPropWidth(): string {
    return (
      (+this.width *
        (document.body.clientWidth - this.menuDiscont) *
        this.floor.proportionWidth) /
        this.floor.floorWith +
      'px'
    );
  }

  get getPropHeight(): string {
    return (
      (+this.height *
        document.body.clientHeight *
        this.floor.proportionHeight) /
        this.floor.height +
      'px'
    );
  }

  get getX(): number {
    return (
      (Number(this.posx) *
        (document.body.clientWidth - this.menuDiscont) *
        this.floor.proportionWidth) /
      this.floor.width
    );
  }

  get getXpx(): string {
    return (
      (Number(this.posx) *
        (document.body.clientWidth - this.menuDiscont) *
        this.floor.proportionWidth) /
        this.floor.width +
      'px'
    );
  }

  get getY(): number {
    return (
      (Number(this.posy) *
        document.body.clientHeight *
        this.floor.proportionHeight) /
      this.floor.height
    );
  }

  get getYpx(): string {
    return (
      (Number(this.posy) *
        document.body.clientHeight *
        this.floor.proportionHeight) /
        this.floor.height +
      'px'
    );
  }

  get getTextOrientation(): string {
    return this.textOrientation == 'horizontal'
      ? 'horizontal-tb'
      : 'vertical-rl';
  }

  public setPosition(x: any, y: any) {
    this.posx = String(
      (x * this.floor.width) /
        (this.floor.proportionWidth *
          (document.body.clientWidth - this.menuDiscont))
    );
    this.posy = String(
      (y * this.floor.height) /
        (document.body.clientHeight * this.floor.proportionHeight)
    );
    this.changed = true;
  }

  reverseY(y: number): number {
    const value = Number(
      (
        (y * this.floor.height) /
        (document.body.clientHeight * this.floor.proportionHeight)
      ).toFixed(3)
    );
    this.posx = String(value);
    return value;
  }

  reverseX(x: number): number {
    const value = Number(
      (
        (x * this.floor.width) /
        ((document.body.clientWidth - this.menuDiscont) *
          this.floor.proportionWidth)
      ).toFixed(3)
    );
    this.posx = String(value);
    return value;
  }

  toJson(): Object {
    return {
      IDline: this.IDline,
      IDbutton: this.IDbutton,
      label: this.label,
      posx: this.posx,
      posy: this.posy,
      width: this.width,
      height: this.height,
      textOrientation: this.textOrientation,
    };
  }
}
