import { IFloor } from '../interfaces/floorinterface';

const MIN_WIDTH = 0.7;
const MAX_WIDTH = 1;

export class Floor implements IFloor {
  width: number;
  height: number;
  background: string = '';
  proportionWidth: number = MIN_WIDTH;
  proportionHeight: number = 0.67;
  withTable: boolean = true;

  constructor() {}

  static fromJson(props: IFloor) {
    let obj = new Floor();
    obj.width = props.width;
    obj.height = props.height;
    obj.background = props.background;
    return obj;
  }

  get backgroundURL(): string {
    return `url("${this.background}")`;
  }

  changeTable() {
    this.withTable = !this.withTable;
    this.proportionWidth = this.withTable ? MIN_WIDTH : MAX_WIDTH;
  }

  get menuDiscont() {
    const res = getComputedStyle(document.body).getPropertyValue('--wroot');
    const value = res.split('px');
    return Number(value[0]);
  }

  get floorWith() {
    return Number(this.width);
  }

  getWidth(): number {
    return document.body.clientWidth * (this.withTable ? MIN_WIDTH : MAX_WIDTH);
  }

  getPropWidth(): string {
    return `calc(${this.getWidth() - this.menuDiscont}px - 20px )`;
  }

  getPropHeight(): string {
    return `calc(${this.getHeight()}px - 150px)`;
  }

  getHeight(): number {
    return document.body.clientHeight;
  }
}
