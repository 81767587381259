import { ITableColumnConfig } from 'src/app/components/tablecomponent/tablecomponent.constants';
import { XLSXdownloader } from './xlsxdownloader.service';

export class Json2XLSX {
  public async download(
    values: any[],
    config: ITableColumnConfig[],
    filename = ''
  ) {
    let json = [];
    values.forEach((item: any) => {
      let obj = {};
      for (let c of config) {
        if (c.columnsize) obj[c.label] = item[c.key];
      }
      json.push(obj);
    });
    const sizes = config.filter((c) => c.columnsize).map((c) => c.columnsize);
    let downloader = new XLSXdownloader();
    await downloader.toExcel(json, filename, sizes);
    console.log(json, sizes);
  }
}
