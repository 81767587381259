<section id="modal">
    <div class="headermodal">
      <div class="titlemodal">{{ title }}</div>
      <icon
        icon="/assets/icons/closeicon.svg"
        width="20px"
        height="20px"
        class="iconmodal"
        mat-dialog-close
      ></icon>
    </div>
    <div class="bodymodal">
      <div class="structmodal">
        <div class="formdata">
          <inputcomponent
            hinttext="ID"
            marginBottom="4px"
            [(data)]="values.IDsector"
            type="text"
            style="width: 20%"
            disabled="true"
          ></inputcomponent>
          <inputcomponent
            hinttext="Setor"
            marginBottom="4px"
            [(data)]="values.sector"
            type="text"
            style="width: 80%"
          ></inputcomponent>
      </div>
      <div style="float: right; margin-top: 12px; margin-bottom: 8px">
        <buttoncomponent
          text="Salvar"
          background="var(--color-primary)"
          (onClick)="send()"
        ></buttoncomponent>
      </div>
    </div>
    <spinner *ngIf="loading">Carregando...</spinner>
  