import * as moment from 'moment';
import {
  IGetOccurrence,
  IOccurrence,
} from '../interfaces/occurrencesinterface';

export class Occurrrence implements IOccurrence {
  IDbutton: string;
  IDline: string;
  IDoccurrences: string;
  IDreasonOff: string;
  IDsector: string;
  IDuser: string;
  dataButtonOff: string;
  dataDone: string;
  dataStart: string;
  happen: string;
  host: string;
  line: string;
  reason: string;
  sector: string;
  timeButtonOff: string;
  timeDone: string;
  timeStart: string;
  userLevel: string;

  public static fromJson(occurrence: IOccurrence) {
    let obj = new Occurrrence();
    obj.IDbutton = occurrence.IDbutton;
    obj.IDline = occurrence.IDline;
    obj.IDoccurrences = occurrence.IDoccurrences;
    obj.IDreasonOff = occurrence.IDreasonOff;
    obj.IDsector = occurrence.IDsector;
    obj.IDuser = occurrence.IDuser;
    obj.dataButtonOff = occurrence.dataButtonOff;
    obj.dataDone = occurrence.dataDone;
    obj.dataStart = occurrence.dataStart;
    obj.happen = occurrence.happen;
    obj.host = occurrence.host;
    obj.line = occurrence.line;
    obj.reason = occurrence.reason;
    obj.sector = occurrence.sector;
    obj.timeButtonOff = occurrence.timeButtonOff;
    obj.timeDone = occurrence.timeDone;
    obj.timeStart = occurrence.timeStart;
    obj.userLevel = occurrence.userLevel;
    return obj;
  }

  public toJson() {
    return {
      IDbutton: this.IDbutton,
      IDline: this.IDline,
      IDoccurrences: this.IDoccurrences,
      IDreasonOff: this.IDreasonOff,
      IDsector: this.IDsector,
      IDuser: this.IDuser,
      dataButtonOff: this.dataButtonOff,
      dataDone: this.dataDone,
      dataStart: this.dataStart,
      happen: this.happen,
      host: this.host,
      line: this.line,
      reason: this.reason,
      sector: this.sector,
      timeButtonOff: this.timeButtonOff,
      timeDone: this.timeDone,
      timeStart: this.timeStart,
      userLevel: this.userLevel,
    };
  }
}

export class GetOccurrence implements IGetOccurrence {
  initDate: string;
  endDate: string;

  public static fromJson(js: IGetOccurrence) {
    let oc = new GetOccurrence();
    oc.endDate = js.endDate;
    oc.initDate = js.initDate;
    return oc;
  }

  get endDateValue(){
    return moment(this.endDate, "DD/MM/YYYY").format("YYYY-MM-DD");
  }

  get initDateValue(){
    return moment(this.initDate, "DD/MM/YYYY").format("YYYY-MM-DD");
  }

  public toJson() {
    return {
      initDate: this.initDateValue,
      endDate: this.endDateValue,
    };
  }
}
