import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { TablecomponentComponent } from 'src/app/components/tablecomponent/tablecomponent.component';
import { ITableColumnConfig, ITableHeaderConfig } from 'src/app/components/tablecomponent/tablecomponent.constants';
import { NewalertComponent } from 'src/app/modals/newalert/newalert.component';
import { Level } from 'src/app/models/levelsmodel';
import { ModalserviceService } from 'src/app/services/modalservice/modalservice.service';
import { LevelsrepositoryService } from 'src/app/services/repository/levels/levelsrepository.service';
import { ArrayInterfaceToArrayClass } from 'src/app/services/uteis/arraytoarrayclass';
import { Json2XLSX } from 'src/app/services/uteis/downloadXLSX';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit {
  public loading: boolean = false;
  public data: Level[] = [];
  public downloader: Json2XLSX = new Json2XLSX();

  /**
   * Configurações de table
   */
  public configTable: ITableColumnConfig[] = [
    {
      key: 'signal',
      signal: { color: ['cor'] },
      label: 'Cor',
      size: '52px',
      alignment: 'center',
    },
    {
      key: 'idNiveis',
      label: 'Nível',
      size: '80px',
      columnsize: 7.5,
      alignment: 'center',
    },
    {
      key: 'label',
      label: 'Descrição',
      size: '1fr',
      columnsize: 50,
      alignment: 'left',
    },
    {
      key: 'tempo',
      label: 'Timer',
      size: '100px',
      columnsize: 50,
      alignment: 'center',
    },
    {
      key: 'desarmavel',
      label: 'Desarmável',
      size: '100px',
      columnsize: 50,
      alignment: 'center',
      booleanOption: {falseValue: "0", trueValue: "1"}
    },
  ];
  public TableHeaderConfig: ITableHeaderConfig = {
    background: 'var(--color-toptable)',
    color: 'var(--color-toptable-contrast)',
  };

  @ViewChild('tableComponent') tableComponent: TablecomponentComponent;

  constructor(
    private sectorrep: LevelsrepositoryService,
    private modal: ModalserviceService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  @HostListener('document:keydown', ['$event'])
  async onKeyDown(event: KeyboardEvent) {
    if (event.key == 'F5') {
      event.preventDefault();
      this.init();
    }
  }

  public async init() {
    this.loading = true;
    try {
      this.data = ArrayInterfaceToArrayClass(
        await this.sectorrep.get(),
        Level
      );
      console.log('Levels => ', this.data);
      // await sleep(2000);
    } catch (error) {
      console.log('Erro => ', error);
    }
    this.loading = false;
  }

  public async new(data: Level = new Level()) {
    try {
      this.modal
        .show(NewalertComponent, {
          height: 'auto',
          width: '600px',
          data: data,
        })
        .then((_) => {
          this.init();
        });
    } catch (error) {}
  }
}
