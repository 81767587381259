import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { environment } from 'src/environments/environment';

const URL_WS = environment.ws;
const TIME_OUT = 1000;

@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  private ws: WebSocket;

  constructor() {}

  private init_conection(
    hashcode: string,
    topics: string[],
    subscriber: Subscriber<any>
  ) {
    console.log('[STATUS] Usuário conectado! => ', hashcode, topics);
    const url =
      URL_WS + '?hashcode=' + hashcode + '&topics=' + topics.join(',');
    this.ws = new WebSocket(url);
    this.configureSubscriber(hashcode, topics, subscriber);
  }

  private configureSubscriber(
    hashcode: string,
    topics: string[],
    subscriber: Subscriber<any>
  ) {
    try {
      this.ws.onmessage = (evt) => {
        try {
          return subscriber.next(JSON.parse(evt.data));
        } catch (error) {
          console.log('[ERROR] Mensagem inadimissível! => ', error);
        }
      };
      this.ws.onerror = (evt) => {
        setTimeout(() => {
          console.log('[ERROR -> onerror] Falha ao conectar ao websocket!');
          this.init_conection(hashcode, topics, subscriber);
        }, TIME_OUT);
      };
      this.ws.onclose = (evt) => {
        setTimeout(() => {
          console.log('[ERROR -> onclose] Falha ao conectar ao websocket!');
          this.init_conection(hashcode, topics, subscriber);
        }, TIME_OUT);
      };
    } catch (error) {
      console.log('[ERROR -> SUBSCRIBER CONFIG] Error on subscriber config!');
    }
  }

  messager(hashcode: string, topics: string[]): Observable<any> {
    return new Observable((subscriber) => {
      try {
        this.init_conection(hashcode, topics, subscriber);
      } catch {
        setTimeout(() => {
          console.log('[ERROR -> SUBSCRIBER] Falha ao conectar ao websocket!');
          this.init_conection(hashcode, topics, subscriber);
        }, TIME_OUT);
      }
    });
  }
}
