<div
  id="squarecomponent"
  #square
  [style.width]="width"
  [style.height]="height"
  [style.left]="posX"
  [style.top]="posY"
  [style.writingMode]="machine.getTextOrientation"
  [style.backgroundColor]="color"
  (mousedown)="onMouseDown($event)"
  [ngClass]="{ moving: inmove }"
  [ngStyle]="{ cursor: blockmove ? 'pointer' : 'move' }"
>
  
  <span>{{ machine.label }} ({{ machine.IDline }})</span>
  <span
    class="btoff"
    title="Botoeira desativada!"
    *ngIf="machine.buttondata.status == 'on'"
  ></span>
</div>
