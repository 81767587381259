import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatDialogModule} from '@angular/material/dialog';

import { AppComponent } from './app.component';
import { SquareComponent } from './components/square/square.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { MenuComponent } from './components/menu/menu.component';
import { EditdashComponent } from './pages/editdash/editdash.component';
import { AboutComponent } from './pages/about/about.component';
import { IconComponent } from './components/icon/icon.component';
import { CreatelineComponent } from './modals/createline/createline.component';
import { EditoccurrenceComponent } from './modals/editoccurrence/editoccurrence.component';
import { InputcomponentComponent } from './components/inputcomponent/inputcomponent.component';
import { TextareacomponentComponent } from './components/textareacomponent/textareacomponent.component';
import { SelectcomponentComponent } from './components/selectcomponent/selectcomponent.component';
import { ButtoncomponentComponent } from './components/buttoncomponent/buttoncomponent.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { OccurrencespageComponent } from './pages/occurrencespage/occurrencespage.component';
import { TablecomponentComponent } from './components/tablecomponent/tablecomponent.component';
import { BarchartComponent } from './components/barchart/barchart.component';
import { PiechartComponent } from './components/piechart/piechart.component';
import { UsersComponent } from './pages/users/users.component';
import { NewuserComponent } from './modals/newuser/newuser.component';
import { LinesComponent } from './pages/lines/lines.component';
import { BillboardComponent } from './components/billboard/billboard.component';
import { SectorsComponent } from './pages/sectors/sectors.component';
import { CreatesectorComponent } from './modals/createsector/createsector.component';
import { ReasonsComponent } from './pages/reasons/reasons.component';
import { CreatereasonComponent } from './modals/createreason/createreason.component';
import { AlertsComponent } from './pages/alerts/alerts.component';
import { NewalertComponent } from './modals/newalert/newalert.component';
import { SwitchComponent } from './components/switch/switch.component';
import { ButtonsComponent } from './pages/buttons/buttons.component';
import { LoginComponent } from './pages/login/login.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';


@NgModule({
  declarations: [
    AppComponent,
    SquareComponent,
    DashboardComponent,
    MenuComponent,
    EditdashComponent,
    AboutComponent,
    IconComponent,
    CreatelineComponent,
    EditoccurrenceComponent,
    InputcomponentComponent,
    TextareacomponentComponent,
    SelectcomponentComponent,
    ButtoncomponentComponent,
    SpinnerComponent,
    OccurrencespageComponent,
    TablecomponentComponent,
    BarchartComponent,
    PiechartComponent,
    UsersComponent,
    NewuserComponent,
    LinesComponent,
    BillboardComponent,
    SectorsComponent,
    CreatesectorComponent,
    ReasonsComponent,
    CreatereasonComponent,
    AlertsComponent,
    NewalertComponent,
    SwitchComponent,
    ButtonsComponent,
    LoginComponent,
    DatepickerComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatSlideToggleModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    FormsModule,
    MatDialogModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
