import { Component, Input, OnInit } from '@angular/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { graph_theme } from '../../constants/themes/theme_graph';

am4core.useTheme(graph_theme);

@Component({
  selector: 'piechart',
  templateUrl: './piechart.component.html',
  styleUrls: ['./piechart.component.scss'],
})
export class PiechartComponent implements OnInit {
  private _data: any[] = [];

  get data() {
    return this._data;
  }
  @Input() set data(values: any[]) {
    this._data = values;
  }

  @Input() idgraph: string = 'piechart';
  @Input() categorylabel: string = 'x';
  @Input() valuelabel: string = 'y';
  @Input() title: string;

  public chart: any;

  constructor() {}

  ngOnDestroy() {
    this.chart.dispose();
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.createChart();
    }, 30);
  }

  public createChart() {
    this.chart = am4core.create(this.idgraph, am4charts.PieChart3D);
    this.chart.data = this.data;
    this.chart.logo.height = -15000;
    this.chart.innerRadius = am4core.percent(50);

    // Set number format
    // this.chart.numberFormatter.numberFormat = "'Qtd.:' #.#";
    this.generateSerie(this.chart);
  }

  generateSerie(chart) {
    let series = chart.series.push(new am4charts.PieSeries3D());
    series.dataFields.value = this.valuelabel;
    series.dataFields.category = this.categorylabel;
    series.slices.template.tooltipText = "{category}: {value}";
    series.labels.template.fontSize = 11;
    // series.ticks.template.disabled = true;
    // series.labels.template.disabled = true;
    series.clustered = false;
    series.columns.template.fill = new am4core.InterfaceColorSet().getFor(
      'alternativeBackground'
    );
    series.columns.template.strokeWidth = 0;
    series.columns.template.radarColumn.cornerRadius = 20;
    // series.columns.template.tooltipText = `[bold]{name}[/bold]\n[font-size:14px]{category}: {value}`;
    
  }
}
