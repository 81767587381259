import { IReason } from '../interfaces/reasonsinterface';

export class Reason implements IReason {
  IDreasonOff: string;
  reasonOff: string;

  public static fromJson(data: IReason) {
    let sector = new Reason();
    sector.IDreasonOff = data.IDreasonOff;
    sector.reasonOff = data.reasonOff;
    return sector;
  }

  public toJson() {
    return { IDreasonOff: this.IDreasonOff, reasonOff: this.reasonOff };
  }
}
