import { Injectable } from '@angular/core';
import { ILevelUser } from 'src/app/interfaces/leveluserinterface';
import { ILine } from 'src/app/interfaces/lineInterface';
import { getLevelByCode, LevelUser } from 'src/app/models/levelusermodel';
import { LoginModel } from 'src/app/models/loginmodel';
import { environment } from 'src/environments/environment';
import { ArrayInterfaceToArrayClass } from '../uteis/arraytoarrayclass';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  private _menu_open: boolean = false;
  private _user: LoginModel = new LoginModel();
  private _machines: ILine[] = [];
  private _levels: LevelUser[] = [];

  constructor() {
    // if (!environment.production) {
    //   this._user.level = '999';
    //   this._user.user = 'Teste';
    //   this.user.name = 'Teste';
    //   this.user.pin = '115590';
    // }
  }

  get machines(): ILine[] {
    return this._machines;
  }

  set machines(machines: ILine[]) {
    this._machines = machines;
  }

  get menuOpened(): boolean {
    return this._menu_open;
  }

  public changeMenuPosition() {
    this._menu_open = !this._menu_open;
  }

  set levels(levels: ILevelUser[]) {
    this._levels = ArrayInterfaceToArrayClass(levels, LevelUser);
  }

  get user(): LoginModel {
    return this._user;
  }

  set user(userdata: LoginModel) {
    this._user = userdata;
  }

  public clearUser() {
    this._user = new LoginModel();
  }

  get isViewer() {
    return Number(this.user.level) == 0;
  }

  get isAdmin() {
    return Number(this.user.level) == 999;
  }

  get levelLoginName(): string {
    return getLevelByCode(this.user.level, this._levels);
  }
}
