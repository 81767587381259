import { ILogin } from '../interfaces/logininterface';

export class LoginModel implements ILogin {
  user: string;
  password: string;
  level: string;
  edit: string;
  viewer: string;
  dataDash: string;
  IDuser:string;
  name: string;
  pin: string;

  public static fromJson(js: ILogin) {
    let login = new LoginModel();
    login.user = js.user;
    login.level = js.level;
    login.edit = js.edit;
    login.viewer = js.viewer;
    login.dataDash = js.dataDash;
    login.IDuser = js.IDuser;
    login.name = js.name;
    login.pin = js.pin;
    return login;
  }

  toJson() {
    return {
      user: this.user,
      password: this.password,
    };
  }
}
