import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { getLevel, LevelUser } from 'src/app/models/levelusermodel';
import { User } from 'src/app/models/usersmodel';
import { LeveluserrepositoryService } from 'src/app/services/repository/leveluser/leveluserrepository.service';
import { UsersrepositoryService } from 'src/app/services/repository/users/usersrepository.service';
import { ArrayInterfaceToArrayClass } from 'src/app/services/uteis/arraytoarrayclass';
import { sendAlert } from 'src/app/services/utils';

@Component({
  selector: 'app-newuser',
  templateUrl: './newuser.component.html',
  styleUrls: ['./newuser.component.scss'],
})
export class NewuserComponent implements OnInit {
  @Input() title: string = 'Novo Usuário';
  @Input() user: User;
  public password_validation: string;
  public levels: LevelUser[] = [];
  public loading: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: User,
    public dialogRef: MatDialogRef<NewuserComponent>,
    public userrep: UsersrepositoryService,
    public leveluserrep: LeveluserrepositoryService
  ) {
    this.user = this.data;
  }

  ngOnInit(): void {
    this.init();
  }

  public async init() {
    this.loading = true;
    try {
      let lvlusers = await this.leveluserrep.get();
      this.levels = ArrayInterfaceToArrayClass(lvlusers, LevelUser);
      console.log('levels => ', this.levels);
      if (this.data.IDuser) {
        this.user = this.data;
        this.title = 'Editando Usuário';
      }
    } catch (error) {}
    this.loading = false;
  }

  get dslevel() {
    return getLevel(this.user, this.levels);
  }

  public validate() {}

  public async send() {
    this.loading = true;
    try {
      if (this.user.IDuser) await this.userrep.edit(this.user);
      else await this.userrep.set(this.user);
      this.dialogRef.close(this.user);
    } catch (error) {
      sendAlert('Erro ao Inserir/Editar Usuário!', 'danger');
    }
    this.loading = false;
  }
}
