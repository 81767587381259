import { IUser } from '../interfaces/usersinterface';

export class User implements IUser {
  email?: string;
  telegram?: string;
  avaible: string = '1';
  IDuser: string;
  cracha: string;
  level: any;
  name: string;
  pin: string;
  user: string;
  password: string;
  userHash: string;
  viewer: any;
  edit: any;
  dataDash: any;
  closeOccurrence: any;
  dslevel: any;

  public static fromJson(data: any) {
    let user = new User();
    user.email = data.email;
    user.telegram = data.telegram;
    user.avaible = data.avaible;
    user.IDuser = data.IDuser;
    user.cracha = data.cracha;
    user.level = data.level;
    user.name = data.name;
    user.pin = data.pin;
    user.user = data.user;
    user.password = data.password;
    user.userHash = data.userHash;
    user.viewer = data.viewer;
    user.edit = data.edit;
    user.dataDash = data.dataDash;
    user.closeOccurrence = data.closeOccurrence;
    user.dslevel = data.dslevel;
    return user;
  }

  public toJson() {
    return {
      cracha: this.cracha,
      level: this.level,
      name: this.name,
      pin: this.pin,
      user: this.user,
      password: this.password,
      email: this.email,
      telegram: this.telegram,
      avaible: this.avaible,
    };
  }

  public toJsonEdit() {
    return {
      where: {
        IDuser: this.IDuser,
      },
      level: this.level,
      cracha: this.cracha,
      name: this.name,
      telegram: this.telegram,
      email: this.email,
      avaible: this.avaible,
    };
  }
}
