import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IUser } from 'src/app/interfaces/usersinterface';
import { User } from 'src/app/models/usersmodel';
import { environment } from 'src/environments/environment';
import { sendAlert } from '../../utils';
import { HeaderJson } from '../headers/jsonheader';

const URL_SERVER = environment.url_server;

@Injectable({
  providedIn: 'root',
})
export class UsersrepositoryService {
  private header: HeaderJson = new HeaderJson();

  constructor(private http: HttpClient) {}

  async get(): Promise<IUser[]> {
    try {
      const res = await this.http
        .get(`${URL_SERVER}/user`, { headers: this.header.jsonheader })
        .toPromise();
      // const res = await this.http.get('/assets/api/users.json').toPromise();
      return res as IUser[];
    } catch (error) {
      console.error(error);
      sendAlert('Erro ao Consultar Usuário!', 'danger');
      return [];
    }
  }

  async set(data: User): Promise<any> {
    try {
      const res = await this.http
        .post(`${URL_SERVER}/user`, data.toJson(), {
          headers: this.header.jsonheader,
        })
        .toPromise();
      console.log(res);
    } catch (error) {
      console.error(error);
      sendAlert('Erro ao Inserir Usuário!', 'danger');
      throw(error);
    }
  }

  async edit(data: User): Promise<any> {
    try {

      const res = await this.http
        .put(`${URL_SERVER}/user`, data.toJsonEdit(), {
          headers: this.header.jsonheader,
        })
        .toPromise();
      console.log(res);
    } catch (error) {
      console.error(error);

      sendAlert('Erro ao Editar Usuário!', 'danger');
      throw(error);
    }
  }
}
