import { Component, Input, OnInit } from '@angular/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { graph_theme } from '../../constants/themes/theme_graph';

am4core.useTheme(graph_theme);

@Component({
  selector: 'barchart',
  templateUrl: './barchart.component.html',
  styleUrls: ['./barchart.component.scss'],
})
export class BarchartComponent implements OnInit {
  private _data: any[] = [];

  get data() {
    return this._data;
  }
  @Input() set data(values: any[]) {
    this._data = values;
  }

  @Input() idgraph: string = 'barchart';
  @Input() xlabel: string = '';
  @Input() ylabel: string = '';
  @Input() title: string;
  @Input() rotate: number;
  @Input() labelpos: [am4core.HorizontalCenter, am4core.VerticalCenter] = [
    'middle',
    'middle',
  ];

  public chart: any;

  constructor() {}

  ngOnDestroy() {
    this.chart.dispose();
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.createChart();
    }, 30);
  }

  public createChart() {
    this.chart = am4core.create(this.idgraph, am4charts.XYChart3D);
    this.chart.logo.height = -15000;
    // Add data
    this.chart.data = this.data;

    // Create axes
    let categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.dataFields.category = this.xlabel;
    categoryAxis.renderer.minGridDistance = 60;
    categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.labels.template.fill = '#7c888e';
    categoryAxis.renderer.labels.template.fontSize = 10;
    categoryAxis.renderer.grid.template.strokeWidth = 0;
    categoryAxis.renderer.grid.template.color = 0;
    categoryAxis.renderer.labels.template.horizontalCenter = this.labelpos[0];
    categoryAxis.renderer.labels.template.verticalCenter = this.labelpos[1];
    categoryAxis.renderer.labels.template.rotation = this.rotate || 0;

    let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.extraMax = 0.1;
    valueAxis.renderer.labels.template.fill = '#7c888e';
    valueAxis.renderer.labels.template.fontSize = 12;
    valueAxis.renderer.grid.template.strokeWidth = 0.5;
    valueAxis.renderer.grid.template.color = 0;

    // Create series
    this.createSeries();
    // if (this.title) {
    //   let title = this.chart.titles.create();
    //   title.text = this.title;
    //   title.fontSize = 14;
    //   title.fill = '#03344c';
    //   title.marginTop = 12;
    //   title.marginBottom = 12;
    // }
  }

  createSeries() {
    let series = this.chart.series.push(new am4charts.ColumnSeries3D());
    series.dataFields.categoryX = this.xlabel;
    series.dataFields.valueY = this.ylabel;
    series.tooltipText = '{valueY.value}';
    series.columns.template.tooltipY = 0;
    series.tooltip.pointerOrientation = 'vertical';

    series.columns.template.strokeOpacity = 0;
    series.columns.template.fillOpacity = 1;

    series.columns.template.column.cornerRadiusTopRight = 10;
    series.columns.template.column.cornerRadiusTopLeft = 10;
    let labelBullet = series.bullets.push(new am4charts.LabelBullet());
    labelBullet.label.verticalCenter = 'bottom';
    labelBullet.label.fontSize = 12;
    labelBullet.label.dy = -18;
    labelBullet.label.text = "{values.valueY.workingValue.formatNumber('#.')}";
    // labelBullet.label.adapter.add("text", (text,target)=>{
    //   console.log("text => ",text);
    //   console.log("target => ",target);
    //   return text;
    // })
  }
}
