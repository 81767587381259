import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ISector } from 'src/app/interfaces/sectorinterface';
import { Sector } from 'src/app/models/sectormodel';
import { environment } from 'src/environments/environment';
import { sendAlert } from '../../utils';
import { HeaderJson } from '../headers/jsonheader';

const URL_SERVER = environment.url_server;

@Injectable({
  providedIn: 'root',
})
export class SectorrepositoryService {
  private header: HeaderJson = new HeaderJson();

  constructor(private http: HttpClient) {}

  async get(): Promise<ISector[]> {
    try {
      // const res = await this.http.get('/assets/api/sector.json').toPromise();
      const res = await this.http
        .get(`${URL_SERVER}/sector`, { headers: this.header.jsonheader })
        .toPromise();
      return res as ISector[];
    } catch (error) {
      console.error(error);
      sendAlert('Erro ao Consultar Setores!', 'danger');
      return [];
    }
  }

  async set(data: Sector): Promise<any> {
    try {
      const res = await this.http
        .post(`${URL_SERVER}/sector`, data.toJson(), {
          headers: this.header.jsonheader,
        })
        .toPromise();
      console.log(res);
    } catch (error) {
      console.error(error);
      sendAlert('Erro ao Inserir Setor!', 'danger');
      throw(error);
    }
  }

  async edit(data: Sector): Promise<any> {
    try {
      const body = {
        where: {
          IDsector: data.IDsector,
        },
        sector: data.sector,
      };
      console.log(body);
      
      const res = await this.http
        .put(`${URL_SERVER}/sector`, body, {
          headers: this.header.jsonheader,
        })
        .toPromise();
      console.log(res);
    } catch (error) {
      console.error(error);
      sendAlert('Erro ao Editar Setor!', 'danger');
      throw(error);
    }
  }
}
