type background = 'danger' | 'success' | 'warning';

const colors = {
  danger: '#9c0217',
  warning: '#e57104',
  success: '#049d02',
};
/**
 * Cria um alerta que, por padrão, é um alerta de erro (vermelho)
 * @param text texto do alerta
 * @param background cor do background do alerta
 * @param color cor do texto do alerta
 * @time tempo que o alerto permanece em tela
 */
export const sendAlert = (
  text: string,
  backgroundColor: background = 'danger',
  color = 'white',
  time = 3000
) => {
  let aviso: HTMLElement = document.createElement('div');
  aviso.innerHTML = text;

  let estilo = aviso.style;
  estilo.backgroundColor = colors[backgroundColor];
  estilo.color = color;
  estilo.transition = 'bottom 0.8s';
  estilo.bottom = '-60px';
  estilo.left = '0';
  estilo.right = '0';
  estilo.margin = '0 auto';
  estilo.position = 'fixed';
  estilo.zIndex = '99999999';
  estilo.width = '33vw';
  estilo.padding = '12px';
  estilo.textAlign = 'center';
  estilo.borderRadius = '5px';

  setTimeout(() => {
    estilo.bottom = '20px';
  }, 100);

  setTimeout(() => {
    estilo.bottom = '-60px';
    setTimeout(() => {
      if (aviso.parentElement) aviso.parentElement.removeChild(aviso);
    }, 800);
  }, time);

  document.body.appendChild(aviso);
};

export const popUpYesNo = (
  title: string,
  message: string,
  buttons: { label: string; role: string; color: 'green' | 'red' }[],
  height: string = '33vh',
  width: string = '33vw'
) => {
  return new Promise((resolve, reject) => {
    const div = document.createElement('div');
    div.style.cssText = `
    background-color: #0302028a;
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: 999999;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
  `;

    const popup = document.createElement('div');
    popup.style.cssText = `
    background-color: #ffff;
    width: ${width};
    height: ${height};
    border-radius: 7px;
    position: relative;
  `;

    const title_text = document.createElement('div');
    title_text.style.cssText = `
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    padding: 12px;
    border-bottom: 1px
    solid #ececec;
    `;
    title_text.innerHTML = title;
    popup.appendChild(title_text);

    const messagediv = document.createElement('div');
    messagediv.style.cssText = `
    font-size: 14px;
    padding: 12px;
    text-align: center;
    `;
    messagediv.innerHTML = message;
    popup.appendChild(messagediv);

    const close = (role: any) => {
      document.body.style.overflowY = 'auto';
      div.parentElement?.removeChild(div);
      resolve(role);
    };

    const bts = document.createElement('div');
    bts.style.cssText = `
    display: flex;
    position: absolute;
    bottom: 8px;
    right: 8px;
    `;
    for (let b of buttons) {
      const bt = document.createElement('div');
      bt.style.cssText = `
        background-color: ${b.color};
        padding: 8px;
        margin: 4px;
        color: white;
        font-size: 12px;
        min-width: 50px;
        text-align: center;
        cursor:pointer;
        border-radius: 3px
    `;

      bt.innerHTML = b.label;
      bt.onclick = () => {
        close(b.role);
      };
      bts.appendChild(bt);
    }

    popup.appendChild(bts);
    div.onclick = (evt) => {
      if (popup) {
        if (!popup.contains(evt.target as Node)) {
          close(null);
        }
      }
    };

    div.appendChild(popup);
    document.body.style.overflow = 'hidden';

    document.body.append(div);
  });
};

export const sendNotification = (
  text: string,
  timeout = 10000,
  height = '120px',
  color = 'white'
) => {
  return new Promise((resolve) => {
    let aviso: HTMLElement = document.createElement('div');

    let estilo = aviso.style;
    estilo.backgroundColor = 'white';
    estilo.color = color;
    estilo.transition = 'bottom 0.8s';
    estilo.right = '12px';
    estilo.position = 'fixed';
    estilo.zIndex = '99999999';
    estilo.width = '300px';
    estilo.height = height;
    estilo.padding = '12px';
    estilo.textAlign = 'center';
    estilo.borderRadius = '5px';
    estilo.display = 'flex';
    estilo.justifyContent = 'center';
    estilo.alignItems = 'center';
    estilo.bottom = `calc(-${height} - 40px)`;
    estilo.userSelect = 'none';
    estilo.outline = '0';

    setTimeout(() => {
      estilo.bottom = '12px';
    }, 100);

    const closer = () => {
      estilo.bottom = `calc(-${height} - 40px)`;
      setTimeout(() => {
        if (aviso.parentElement) aviso.parentElement.removeChild(aviso);
      }, 800);
    };

    let timer_close = setTimeout(() => {
      closer();
      resolve(false);
    }, timeout);

    let textdiv: HTMLElement = document.createElement('div');
    textdiv.innerHTML = text;
    textdiv.onclick = () => {
      closer();
      resolve(true);
    };

    let close: HTMLElement = document.createElement('div');
    close.innerHTML = 'X';
    close.style.position = 'absolute';
    close.style.right = '12px';
    close.style.top = '8px';
    close.style.padding = '4px';
    close.style.fontWeight = 'bold';
    close.style.cursor = 'pointer';

    close.onclick = () => {
      clearTimeout(timer_close);
      closer();
      resolve(false);
    };

    aviso.appendChild(close);
    aviso.appendChild(textdiv);

    document.body.appendChild(aviso);
  });
};

export const goTop = () => {
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
};

export const inputComponent = (hint: string, margin: string = '12px') => {
  let div = document.createElement('div');
  let input = document.createElement('input');
  let hinttext = document.createElement('div');

  input.style.cssText = `
    box-sizing: border-box;
    width: 100%;
    background-color: white;
    background-color: white;
    border: 1px solid #5c5b5b;
    border-radius: 3px;
    font-size: 12px;
    padding: 8px;
    outline: none;
    `;

  hinttext.style.cssText = `
    background-color: white;
    color: #5c5b5b;
    position: absolute;
    left: 12px;
    font-size: 14px;
    top: 15px;
    heigth: 14px;
    transition: all 0.05s
  `;

  input.addEventListener('focus', () => {
    hinttext.style.top = '0px';
    hinttext.style.fontSize = '12px';
    hinttext.style.color = '#2662bd';
    input.style.border = '1px solid #2662bd';
  });

  input.addEventListener('focusout', () => {
    hinttext.style.top = '15px';
    hinttext.style.fontSize = '14px';
    hinttext.style.color = '#5c5b5b';
    input.style.border = '1px solid #5c5b5b';
  });

  div.style.cssText = `
    background-color: white;
    padding: 1px;
    padding-top: 8px;
    position: relative;
    overflow: hidden;
    margin: ${margin}
  `;

  hinttext.innerText = hint;
  div.appendChild(input);
  div.appendChild(hinttext);

  return { div: div, input: input };
};

export const popUpInput = (
  title: string,
  message: string,
  hint: string,
  buttons: { label: string; role: string; color: 'green' | 'red' | 'blue' }[],
  height: string = '33vh',
  width: string = '33vw'
) => {
  return new Promise((resolve, reject) => {
    const div = document.createElement('div');
    div.style.cssText = `
    background-color: #0302028a;
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: 999999;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
  `;

    const popup = document.createElement('div');
    popup.style.cssText = `
    background-color: #ffff;
    width: ${width};
    height: ${height};
    border-radius: 7px;
    position: relative;
  `;

    const title_text = document.createElement('div');
    title_text.style.cssText = `
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    padding: 12px;
    border-bottom: 1px
    solid #ececec;
    `;
    title_text.innerHTML = title;
    popup.appendChild(title_text);

    const messagediv = document.createElement('div');
    messagediv.style.cssText = `
    font-size: 14px;
    padding: 12px;
    text-align: center;
    `;
    messagediv.innerHTML = message;
    popup.appendChild(messagediv);

    const close = (role: any) => {
      document.body.style.overflowY = 'auto';
      div.parentElement?.removeChild(div);
      resolve({ role: role, value: ipc.input.value });
    };

    const ipc = inputComponent(hint);
    popup.appendChild(ipc.div);

    const bts = document.createElement('div');
    bts.style.cssText = `
    display: flex;
    position: absolute;
    bottom: 8px;
    right: 8px;
    `;
    for (let b of buttons) {
      const bt = document.createElement('div');
      bt.style.cssText = `
        background-color: ${b.color};
        padding: 8px;
        margin: 4px;
        color: white;
        font-size: 12px;
        min-width: 50px;
        text-align: center;
        cursor:pointer;
        border-radius: 3px
    `;

      bt.innerHTML = b.label;
      bt.onclick = () => {
        close(b.role);
      };
      bts.appendChild(bt);
    }

    popup.appendChild(bts);
    div.onclick = (evt) => {
      if (popup) {
        if (!popup.contains(evt.target as Node)) {
          close(null);
        }
      }
    };

    div.appendChild(popup);
    document.body.style.overflow = 'hidden';

    document.body.append(div);
  });
};

export const sleep = (timer: number) => {
  return new Promise((resolve) => setTimeout(() => {resolve("")}, timer));
};
