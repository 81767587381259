import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Line } from 'src/app/models/linemodel';

@Component({
  selector: 'squarecomponent',
  templateUrl: './square.component.html',
  styleUrls: ['./square.component.scss'],
})
export class SquareComponent implements OnInit {
  private _machine: Line = new Line();
  @Input() blockmove: boolean = false;
  @Input() rounded: boolean = false;
  @Input() color = 'var(--inactive)';
  public offsetX = 0;
  public offsetY = 0;

  @ViewChild('square') square: ElementRef;

  @Output() machineChange = new EventEmitter<Line>();
  @Output() saveChanges = new EventEmitter<Line>();

  public inmove: boolean = false;

  @Input()
  set machine(machine: Line) {
    this.machineChange.emit(machine);
    this._machine = machine;
  }

  get machine() {
    return this._machine;
  }

  constructor() {}

  ngOnInit(): void {}

  get posX() {
    return this.machine.getXpx;
  }

  get posY() {
    return this.machine.getYpx;
  }

  get width() {
    return this.machine.getPropWidth;
  }

  get height() {
    return this.machine.getPropHeight;
  }

  ngAfterViewInit() {}

  public onMouseDown(evt): void {
    if (!this.blockmove) {
      this.inmove = true;
      // console.log(evt);

      this.offsetX = this.square.nativeElement.offsetLeft - evt.clientX;
      this.offsetY = this.square.nativeElement.offsetTop - evt.clientY;
      console.log(this.offsetX, this.offsetY);
      document.addEventListener('mouseup', (evt) => this.onMouseUp(evt));
      document.addEventListener(
        'mousemove',
        (evt) => this.onMouseMove(evt)
      );
    }
  }

  onMouseUp(evt): void {
    this.inmove = false;
    this.saveChanges.emit(this.machine);
  }

  public onMouseMove(evt): void {
    evt.preventDefault();
    if (this.inmove) {
      // console.log(evt);
      const mousePosition = {
        x: evt.clientX,
        y: evt.clientY,
      };
      this.machine.setPosition(
        mousePosition.x + this.offsetX,
        mousePosition.y + this.offsetY
      );
    }
  }
}
