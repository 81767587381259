import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { TablecomponentComponent } from 'src/app/components/tablecomponent/tablecomponent.component';
import {
  ITableColumnConfig,
  ITableHeaderConfig,
} from 'src/app/components/tablecomponent/tablecomponent.constants';
import { CreatesectorComponent } from 'src/app/modals/createsector/createsector.component';
import { Sector } from 'src/app/models/sectormodel';
import { ModalserviceService } from 'src/app/services/modalservice/modalservice.service';
import { SectorrepositoryService } from 'src/app/services/repository/sectors/sectorrepository.service';
import { ArrayInterfaceToArrayClass } from 'src/app/services/uteis/arraytoarrayclass';
import { Json2XLSX } from 'src/app/services/uteis/downloadXLSX';

@Component({
  selector: 'app-sectors',
  templateUrl: './sectors.component.html',
  styleUrls: ['./sectors.component.scss'],
})
export class SectorsComponent implements OnInit {
  public loading: boolean = false;
  public data: Sector[] = [];
  public downloader: Json2XLSX = new Json2XLSX();

  /**
   * Configurações de table
   */
  public configTable: ITableColumnConfig[] = [
    {
      key: 'IDsector',
      label: 'ID',
      size: '50px',
      columnsize: 7.5,
      alignment: 'center',
    },
    {
      key: 'sector',
      label: 'Setor',
      size: '1fr',
      columnsize: 50,
      alignment: 'left',
    },
  ];
  public TableHeaderConfig: ITableHeaderConfig = {
    background: 'var(--color-toptable)',
    color: 'var(--color-toptable-contrast)',
  };

  @ViewChild('tableComponent') tableComponent: TablecomponentComponent;

  constructor(
    private sectorrep: SectorrepositoryService,
    private modal: ModalserviceService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  @HostListener('document:keydown', ['$event'])
  async onKeyDown(event: KeyboardEvent) {
    if (event.key == 'F5') {
      event.preventDefault();
      this.init();
    }
  }

  public async init() {
    this.loading = true;
    try {
      this.data = ArrayInterfaceToArrayClass(
        await this.sectorrep.get(),
        Sector
      );
      console.log('Sectors => ', this.data);
      // await sleep(2000);
    } catch (error) {
      console.log('Erro => ', error);
    }
    this.loading = false;
  }

  public async new(data: Sector = new Sector()) {
    try {
      this.modal
        .show(CreatesectorComponent, {
          height: 'auto',
          width: '600px',
          data: data,
        })
        .then((_) => this.init());
    } catch (error) {}
  }
}
