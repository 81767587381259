import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IGetOccurrence, IOccurrence } from 'src/app/interfaces/occurrencesinterface';
import { CloseOccurrences } from 'src/app/models/closeoccurrencesmodel';
import { environment } from 'src/environments/environment';
import { sendAlert } from '../../utils';
import { HeaderJson } from '../headers/jsonheader';

const URL_SERVER = environment.url_server;

@Injectable({
  providedIn: 'root',
})
export class OccurrencesrepositoryService {
  private header: HeaderJson = new HeaderJson();

  constructor(private http: HttpClient) {}

  async get(props: IGetOccurrence): Promise<IOccurrence[]> {
    try {
      console.log("URL => ", `${URL_SERVER}/listOccurrences`);
      console.log("BODY => ", props);
      
      
      const res = await this.http
        .post(`${URL_SERVER}/listOccurrences`, props, {
          headers: this.header.jsonheader,
        })
        .toPromise();
      console.log(res);

      return res as IOccurrence[];
    } catch (error) {
      console.error(error);
      sendAlert('Erro ao Consultar Ocorrências!', 'danger');
      throw error;
    }
  }

  async getUnclosed(): Promise<IOccurrence[]> {
    try {
      const body = {
        unclose: 1,
      };
      const res = await this.http
        .post(`${URL_SERVER}/occurrences`, body, {
          headers: this.header.jsonheader,
        })
        .toPromise();
      return res as IOccurrence[];
    } catch (error) {
      console.error(error);
      sendAlert('Erro ao Consultar Ocorrências Abertas!', 'danger');
      throw error;
    }
  }

  async close(closeOccurrence: CloseOccurrences): Promise<any> {
    try {
      console.log('Fechando ocorrência => ', closeOccurrence.toJson());

      const res = await this.http
        .post(`${URL_SERVER}/closeOccurrence`, closeOccurrence.toJson(), {
          headers: this.header.jsonheader,
        })
        .toPromise();
      return res;
    } catch (error) {
      const err = error as HttpErrorResponse;
      sendAlert(`Erro ao Fechar Ocorrência: ${err.error.message}`, 'danger');
      throw error;
    }
  }
}
