import { ILevel } from '../interfaces/levelsinterface';

export class Level implements ILevel {
  desarmavel: string = '1';
  cor: string = '#2b282e';
  idNiveis: string;
  label: string;
  tempo: string;

  public static fromJson(data: ILevel) {
    let level = new Level();
    level.cor = data.cor;
    level.idNiveis = data.idNiveis;
    level.label = data.label;
    level.tempo = data.tempo;
    level.desarmavel = data.desarmavel;
    return level;
  }

  public toJson() {
    return {
      idNiveis: this.idNiveis,
      desarmavel: this.desarmavel,
      cor: this.cor,
      label: this.label,
      tempo: this.tempo,
    };
  }
}
