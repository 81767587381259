import * as am4core from '@amcharts/amcharts4/core';

export const graph_theme = (target: am4core.ColorSet) => {
  target.list = [
    am4core.color('#008dce'),
    am4core.color('#220f38'),
    am4core.color('#8a31f6'),
    am4core.color('#70c2b4'),
    am4core.color('#00ce75'),
    
  ];
};
