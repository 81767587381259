import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ILine } from 'src/app/interfaces/lineInterface';
import { Line } from 'src/app/models/linemodel';
import { environment } from 'src/environments/environment';
import { sendAlert } from '../../utils';
import { HeaderJson } from '../headers/jsonheader';

const URL_SERVER = environment.url_server;

@Injectable({
  providedIn: 'root',
})
export class LinesrepositoryService {
  private header: HeaderJson = new HeaderJson();

  constructor(private http: HttpClient) {}

  async get(): Promise<ILine[]> {
    try {
      const res = await this.http
        .get(`${URL_SERVER}/lines`, { headers: this.header.jsonheader })
        .toPromise();
      // const res = await this.http.get("/assets/api/items.json").toPromise();
      return res as ILine[];
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  async set(data: Line): Promise<any> {
    try {
      console.log(data.toJson());

      const res = await this.http
        .post(`${URL_SERVER}/lines`, data.toJson(), {
          headers: this.header.jsonheader,
        })
        .toPromise();
      console.log(res);
    } catch (error) {
      console.error(error);
      sendAlert('Erro ao Inserir Alerta!', 'danger');
      throw error;
    }
  }

  async edit(data: Line): Promise<any> {
    try {
      const body = {
        where: {
          IDline: data.IDline,
        },
        label: data.label,
        posx: data.posx,
        posy: data.posy,
        width: data.width,
        height: data.height,
        textOrientation: data.textOrientation,
        IDbutton: data.IDbutton,
      };
      console.log(body);

      const res = await this.http
        .put(`${URL_SERVER}/lines`, body, {
          headers: this.header.jsonheader,
        })
        .toPromise();
      console.log(res);
    } catch (error) {
      console.error(error);

      sendAlert('Erro ao Editar Alerta!', 'danger');
      throw error;
    }
  }
}
