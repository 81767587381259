<section id="editoccurrence">
  <div class="headeroccurrence">
    <div class="titleoccurrence">
      {{ title }}
    </div>
    <icon
      icon="/assets/icons/closeicon.svg"
      width="20px"
      height="20px"
      class="iconoccurrence"
      mat-dialog-close
    ></icon>
  </div>
  <div class="bodyoccurrence">
    <div class="text">
      Você deseja fechar a ocorrência da linha de produção
      <b>{{ line.label }} ({{ line.IDline }})</b>?
    </div>
    <br />
    <div class="formoccurrence">
      <!-- <div class="labelformoccurrence">Digite seu PIN:</div> -->
      <inputcomponent
        hinttext="ID Ocorrência"
        magin="12px"
        [(data)]="occurrence.IDoccurrences"
        type="text"
        style="width: 34%"
        disabled="true"
      ></inputcomponent>
      <inputcomponent
        hinttext="Data Ocorrência"
        magin="12px"
        [(data)]="occurrence.dataStart"
        type="text"
        style="width: 33%"
        disabled="true"
      ></inputcomponent>
      <inputcomponent
        hinttext="Hora Ocorrência"
        magin="12px"
        [(data)]="occurrence.timeStart"
        type="text"
        style="width: 33%"
        disabled="true"
      ></inputcomponent>
      <inputcomponent
        hinttext="PIN"
        magin="12px"
        [(data)]="closeOccurrence.pin"
        [disabled]="line.buttondata.status == 'on'"
        [error]="line.buttondata.status == 'on' ? 'Botoeira ativada!' : null"
        type="password"
        style="width: 100%"
      ></inputcomponent>
      <selectcomponent
        hinttext="Motivo da Parada"
        magin="12px"
        (selectedChange)="closeOccurrence.IDreasonOff = $event.IDreasonOff"
        [(data)]="reasons"
        [pattern]="closeOccurrence.IDreasonOff"
        labelkey="reasonOff"
        idkey="IDreasonOff"
        style="width: 100%"
      ></selectcomponent>
      <selectcomponent
        hinttext="Setor a Ser Acionado"
        magin="12px"
        [(data)]="sectors"
        (selectedChange)="closeOccurrence.IDsector = $event.IDsector"
        [pattern]="closeOccurrence.IDsector"
        labelkey="sector"
        idkey="IDsector"
        style="width: 100%"
      ></selectcomponent>
      <textareacomponent
        hinttext="Informações"
        magin="12px"
        [(data)]="closeOccurrence.text"
        style="width: 100%"
      ></textareacomponent>
    </div>
    <div style="float: right">
      <buttoncomponent
        text="Enviar"
        background="var(--legend-green)"
        (onClick)="send()"
      ></buttoncomponent>
    </div>
  </div>
  <spinner *ngIf="loading">Carregando...</spinner>
</section>
