import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

interface ModalPropsInterface{
  data?:any;
  width?:string;
  height?:string;
}

@Injectable({
  providedIn: 'root',
})

export class ModalserviceService {
  constructor(private dialog: MatDialog) {}

  async show(component: any, props:ModalPropsInterface = { data: null, width: '85vw', height: '85vh' }) {
    const dialogRef = this.dialog.open(component, {
      data: props.data,
      width: props.width || "85vw",
      height: props.height || "85vh",
      position: {},
    });
    return await dialogRef.afterClosed().toPromise();
  }
}
